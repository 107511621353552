/* eslint-disable prettier/prettier */
import { defineComponent, onMounted, ref } from 'vue';
import SButtonLogout from '@/components/stemdo-components/s-button-logout/SButtonLogout.vue';
import SSelectLang from '@/components/stemdo-components/s-select-lang/SSelectLang.vue';
import clientItems from './main-drawer-items-data/clientItems';
import AuthToken from '@/shared/security/AuthToken';
import { Authority } from '@/shared/security/authority';
import managerItems from './main-drawer-items-data/managerItems';
import MainDrawerItem from './main-drawer-item/MainDrawerItem.vue';

export default defineComponent({
  components: {
    SButtonLogout,
    SSelectLang,
    MainDrawerItem,
  },
  setup() {
    const version = VERSION;
    const items = ref([]);

    onMounted(() => {
      if (AuthToken.hasAnyAuthority([Authority.CLIENT])) {
        items.value = clientItems;
      } else if (AuthToken.hasAnyAuthority([Authority.GESTOR])) {
        items.value = managerItems;
      }
    });

    return { items, version };
  },
});
