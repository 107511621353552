import Vue from 'vue';
import Vuex from 'vuex';
import { accountStore } from '../store/accountStore';
import { translationStore } from '../store/translationStore';
import { cartStore } from '../store/cartStore';
import { filterStore } from '@/store/filterStore';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    accountStore,
    translationStore,
    cartStore,
    filterStore,
  },
});

export default store;
export const useStore = () => store;
