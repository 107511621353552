var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "info jhi-item-count" }, [
    _vm.i18nEnabled
      ? _c("span", {
          domProps: {
            textContent: _vm._s(
              _vm.$t("global.item-count", {
                first: _vm.first,
                second: _vm.second,
                total: _vm.total,
              })
            ),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    !_vm.i18nEnabled
      ? _c("span", [
          _vm._v(
            "\n    Showing " +
              _vm._s(
                (_vm.page - 1) * _vm.itemsPerPage === 0
                  ? 1
                  : (_vm.page - 1) * _vm.itemsPerPage + 1
              ) +
              " -\n    " +
              _vm._s(
                _vm.page * _vm.itemsPerPage < _vm.total
                  ? _vm.page * _vm.itemsPerPage
                  : _vm.total
              ) +
              "\n    of " +
              _vm._s(_vm.total) +
              " items.\n  "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }