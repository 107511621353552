import { defineComponent, inject, reactive, ref, toRefs, watch } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import { useStore } from '@/plugins/vuex';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import { useI18N } from '@/plugins/i18n';
import axios from 'axios';
import { ITeamRequest, TeamRequest } from '@/shared/model/team-request.model';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import TeamRequestService from '@/entities/team-request/team-request.service';
import { useRouter } from '@/plugins/router';

export default defineComponent({
  props: {
    stemdoers: {
      type: Array as () => IStemdoer[],
      required: true,
    },
    hourlyCost: {
      type: Number,
      required: true,
    },
  },
  components: {
    SButton,
    SModalCentered,
  },
  setup(props) {
    const teamRequestService: TeamRequestService = inject('teamRequestService')!;
    const store = useStore();
    const router = useRouter();
    const baseUrl = window.location.origin;
    const { hourlyCost } = toRefs(props);
    const i18n = useI18N();
    const clientSelected = store.getters['cartStore/clientSelected'];
    const teamRequest = reactive<ITeamRequest>(new TeamRequest());

    const detailUrl = ref('');

    const openApproveModal = ref(false);
    const openFormModal = ref(false);

    const showExpandTeam = ref(false);

    const teamRequestName = ref('');
    const teamRequestNameRef = ref(null);

    const teamRequestDescription = ref('');
    const teamRequestDescriptionRef = ref(null);

    const menuStartDate = ref(false);
    const menuEndDate = ref(false);

    const formattedStartDate = ref('');
    const formattedEndDate = ref('');

    const initDate = ref(new Date().toISOString().slice(0, 10));
    const startDate = ref(null);
    const finishDate = ref(null);
    const showEndDate = ref(false);
    const endDateInput = ref(null);

    const endDate = ref(new Date(new Date().getFullYear(), 11, 32).toISOString().slice(0, 10));
    const maxEndDate = ref(new Date(new Date().getFullYear() + 1, 11, 31).toISOString().slice(0, 10));

    const wrongDate = ref(false);
    const isButtonDisabled = ref(true);

    const redirectToExplore = () => {
      router.push({ name: 'exploreManager' });
    };

    const checkWrongDate = () => {
      const isInitDate = startDate.value && startDate.value !== '';
      const isEndDate = finishDate.value && finishDate.value !== '';
      const today = new Date().toISOString().slice(0, 10);
      formatDateLanguage();

      if (isInitDate) {
        wrongDate.value = startDate.value === today || (isEndDate && finishDate.value < startDate.value);
      } else {
        wrongDate.value = true;
      }

      isButtonDisabled.value = wrongDate.value;
    };

    const formatDateLanguage = () => {
      const today = new Date().toISOString().slice(0, 10);

      if (startDate.value == null || startDate.value === today) {
        startDate.value = today;
        formattedStartDate.value = i18n.t('label.teams.selected.initDateTip') as string;
      } else {
        try {
          formattedStartDate.value = i18n.d(new Date(startDate.value), 'long').replace(/ de /g, ' ');
        } catch (error) {
          console.error('Error formatting start date:', error);
        }
      }

      if (finishDate.value == null) {
        showEndDate.value = false;
        formattedEndDate.value = i18n.t('label.teams.selected.endDateTip') as string;
      } else {
        try {
          formattedEndDate.value = i18n.d(new Date(finishDate.value), 'long').replace(/ de /g, ' ');
        } catch (error) {
          console.error('Error formatting end date:', error);
        }
      }
    };

    const clearFinishDate = () => {
      finishDate.value = null;
      showEndDate.value = false;
      menuEndDate.value = false;
    };

    const toggleEndDateInput = () => {
      showEndDate.value = !showEndDate.value;

      if (showEndDate.value) {
        finishDate.value = endDateInput.value;
        checkWrongDate();
      } else {
        clearFinishDate();
      }
    };

    const blockTeam = () => {
      if (teamRequestNameRef.value) {
        teamRequestName.value = teamRequestNameRef.value.value;
      }
      if (teamRequestDescriptionRef.value) {
        teamRequestDescription.value = teamRequestDescriptionRef.value.value;
      }

      if (!isButtonDisabled.value) {
        const arrayStemdoers = props.stemdoers;
        const initDate = startDate.value;
        const endDate = finishDate.value;
        const vertical = arrayStemdoers.values[0]?.verticals[0] ?? '';
        const clientId = clientSelected?.id ?? '';
        const clientName = clientSelected
          ? `${clientSelected.name.charAt(0).toLocaleUpperCase()}${clientSelected.name.slice(1)} ${clientSelected.surname
              .charAt(0)
              .toLocaleUpperCase()}.`
          : '';
        const clientEmail = clientSelected?.email ?? '';

        const ratesList = Array.from(arrayStemdoers.values()).reduce((acc, stemdoerRates) => {
          acc[stemdoerRates.id] = stemdoerRates.rate;
          return acc;
        }, {});

        const myData = {
          isTeam: true,
          ratesList: ratesList,
          stemdoerShowBadge: {},
          initDate: initDate,
          endDate: endDate,
          locale: store.getters['currentLanguage'],
          paramId: '',
          idClient: clientId,
          nameClient: clientName,
          emailClient: clientEmail,
          nameStemdoers: [],
        };

        const myJSON = JSON.stringify(myData);

        teamRequest.name = teamRequestName.value;
        teamRequest.client = { id: clientId };
        teamRequest.vertical = vertical;
        teamRequest.stemdoerRates = Array.from(arrayStemdoers.values()).map(stemdoer => ({
          stemdoer: stemdoer,
          rate: stemdoer.rate,
        }));
        teamRequest.status = RequestStatus.VALIDATED;
        teamRequest.initDate = initDate;
        teamRequest.endDate = endDate;
        teamRequest.createdAt = new Date();
        teamRequest.paramsUrl = myJSON;
        teamRequest.jobDescription = teamRequestDescription.value;
        teamRequest.hourlyRate = hourlyCost.value;

        const processRequestEmail = (teamRequestId: string, clientEmail: string): void => {
          myData.paramId = teamRequestId;
          let listIdMongo = '';
          for (const stemdoer of Array.from(arrayStemdoers.values())) {
            listIdMongo += `${stemdoer.id},`;
            myData.nameStemdoers.push(
              `${stemdoer.name.charAt(0).toLocaleUpperCase()}${stemdoer.name.slice(1)} ${stemdoer.surname
                .charAt(0)
                .toLocaleUpperCase()}${stemdoer.surname.slice(1)}`
            );
          }
          listIdMongo = listIdMongo.slice(0, -1);
          const myJSON = JSON.stringify(myData);
          axios
            .get(`api/import/pdf?email=${clientEmail}`, {
              params: {
                listIdMongo: listIdMongo,
                dataNecessary: myJSON,
              },
            })
            .catch((err: Error) => {
              console.error(err);
            });
        };

        let isCreating = false;
        teamRequestService
          .create(teamRequest)
          .then(res => {
            if (clientSelected) {
              processRequestEmail(res.id, clientSelected.email);
              generateDetailLink(res);
            }
            isCreating = true;
          })
          .catch(error => {
            isCreating = false;
            console.error(error);
          })
          .finally(() => {
            isCreating = false;
          });
      }
    };

    const generateDetailLink = teamRequest => {
      if (teamRequest.status === RequestStatus.VALIDATED) {
        const link = router.resolve({ name: 'teamRequestByIdClient', params: { id: teamRequest.id } }).href;
        detailUrl.value = window.location.origin + link;
        return detailUrl.value;
      }
      return null;
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(detailUrl.value);
    };

    const openFormModalHandler = () => {
      openFormModal.value = true;
    };
    const openApproveModalHandler = () => {
      blockTeam();
      openApproveModal.value = true;
    };

    const clearCart = () => {
      store.commit('cartStore/clearCart');
      const cartTotalItems = store.getters['cartStore/cartTotalItems'];
      if (cartTotalItems < 1) {
        redirectToExplore();
      }
    };

    return {
      openFormModal,
      openApproveModal,
      clientSelected,
      hourlyCost,
      teamRequestName,
      teamRequestDescription,
      initDate,
      startDate,
      finishDate,
      formattedStartDate,
      formattedEndDate,
      endDate,
      maxEndDate,
      menuStartDate,
      menuEndDate,
      showEndDate,
      wrongDate,
      isButtonDisabled,
      showExpandTeam,
      detailUrl,
      copyToClipboard,
      formatDateLanguage,
      clearFinishDate,
      checkWrongDate,
      toggleEndDateInput,
      openFormModalHandler,
      openApproveModalHandler,
      clearCart,
    };
  },
});
