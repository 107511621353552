import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const opts = {
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#4237FF',
        secondary: '#8859FF',
        tertiary: '#00C2B1',
        quaternary: '#EF2630',
        text: {
          base: '#222226',
          lighten1: '#606061',
          lighten2: '#DBDBE0',
          lighten3: '#ECECEF',
          lighten4: '#F9F9F9',
        },
        background: {
          base: '#FFFFFF',
          darken1: '#F9F9F9',
          darken2: '#ECECEF',
          darken3: '#222226',
        },
        border: {
          base: '#ECECEF',
          darken1: '#DBDBE0',
        },
        btnPrimary: {
          base: '#4237FF',
          darken1: '#0C00D1',
        },
        btnPrimaryText: {
          base: '#FFFFFF',
        },
        btnSecondary: {
          base: '#4237FF',
          darken1: '#0C00D1',
        },
        btnSecondaryBorder: {
          base: '#DBDBE0',
        },
        btnTertiary: {
          base: '#606061',
          darken1: '#444',
        },
        btnTertiaryBorder: {
          base: '#DBDBE0',
        },
      },
    },
  },
};

export default new Vuetify(opts);
