const managerItems = [
  {
    title: 'nav.requests',
    iconName: 'requests',
    iconActiveName: 'requests-blue',
    iconDisableName: 'requests-lighten',
    path: '/manager/team-requests',
    activePaths: ['/manager/team-requests'],
    disable: false,
    isTest: false,
  },
  {
    title: 'nav.explore',
    iconName: 'explore',
    iconActiveName: 'explore-blue',
    iconDisableName: 'explore-lighten',
    path: '/manager/explore',
    activePaths: ['/manager/explore', '/manager/checkout'],
    disable: false,
    isTest: false,
  },
  {
    title: 'nav.inbox',
    iconName: 'inbox',
    iconActiveName: 'inbox-blue',
    iconDisableName: 'inbox-lighten',
    path: '',
    activePaths: [],
    disable: true,
    isTest: false,
  },
  {
    title: 'nav.settings',
    iconName: 'settings',
    iconActiveName: 'settings-blue',
    iconDisableName: 'settings-lighten',
    path: '',
    activePaths: [],
    disable: true,
    isTest: false,
  },
];

export default managerItems;
