var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.item.items
        ? _c("v-hover", {
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ hover }) {
                    return [
                      _c(
                        "v-list-item",
                        {
                          key: _vm.item.title,
                          class: [
                            "ml-5 pl-0 ",
                            !_vm.isActiveLink(
                              _vm.item.activePaths,
                              _vm.$route.path
                            ) && hover
                              ? "hover-item"
                              : "",
                            _vm.isActiveLink(
                              _vm.item.activePaths,
                              _vm.$route.path
                            )
                              ? "active-item v-list-item--active"
                              : "",
                          ],
                          attrs: { disabled: _vm.item.disable },
                          on: {
                            click: function ($event) {
                              return _vm.goToPage(
                                _vm.item.path,
                                _vm.$route.path
                              )
                            },
                          },
                        },
                        [
                          _c("ItemContent", {
                            attrs: {
                              item: _vm.item,
                              isActive: _vm.isActiveLink(
                                _vm.item.activePaths,
                                _vm.$route.path
                              ),
                              hover: hover,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3096145578
            ),
          })
        : _c("ItemGroup", {
            attrs: { item: _vm.item, isActiveLink: _vm.isActiveLink },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }