/* eslint-disable prettier/prettier */
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '70%',
    },
    closeModal: {
      type: Function as PropType<() => void>,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const close = () => {
      props.closeModal();
      document.documentElement.style.overflow = 'auto';
    };

    const clickOutside = () => {
      if (!props.persistent) {
        close();
      }
    };

    return { close, clickOutside, open };
  },
});
