import { useStore } from '@/plugins/vuex';
import localStorageFacade from '../local-store-facade/LocalStoreFacade';

export const logout = () => {
  const store = useStore();
  store.commit('logout');
  store.commit('cartStore/clearCart');
  store.commit('filterStore/clearToInitState');
  localStorageFacade.clearSession();
};
