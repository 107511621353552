import { Authority } from '@/shared/security/authority';
import ExplorePage from '@/pages/explore-page/ExplorePage.vue';
import RequestsPage from '@/pages/requests-page/RequestsPage.vue';
import RequestDetail from '@/sections/request-detail-manager/RequestDetail.vue';
import CheckoutManager from '@/sections/checkout-manager/CheckoutManager.vue';

export default [
  {
    path: '/manager/explore',
    name: 'exploreManager',
    component: ExplorePage,
    meta: { authorities: [Authority.GESTOR] },
  },
  {
    path: '/manager/team-requests',
    name: 'teamRequestsManager',
    component: RequestsPage,
    meta: { authorities: [Authority.GESTOR] },
  },
  {
    path: '/manager/checkout',
    name: 'checkoutManager',
    component: CheckoutManager,
    meta: { authorities: [Authority.GESTOR] },
  },
  {
    path: '/manager/team-requests/:id',
    name: 'teamRequestByIdManager',
    component: RequestDetail,
    meta: { authorities: [Authority.GESTOR] },
  },
];
