<template>
  <v-list-group
    @mouseenter="onGroupParentHover(true)"
    @mouseleave="onGroupParentHover(false)"
    :class="[
      'ml-5 pl-0 pr-2 mb-1',
      !isParentActiveLink(item.items, $route.path) && isGroupParentHovered ? 'hover-item' : '',
      isParentActiveLink(item.items, $route.path) ? 'active-parent-subitems' : '',
    ]"
    :key="item.title"
    :disabled="item.disable"
    :value="isParentExpanded"
  >
    <template v-slot:activator>
      <ItemContent :item="item" :isActive="isParentActiveLink(item.items, $route.path)" :hover="isGroupParentHovered" />
    </template>

    <SubItemContent
      v-for="(subItem, index) in item.items"
      :key="subItem.title"
      :item="subItem"
      :isLast="index === item.items.length - 1"
      :isActiveLink="isActiveLink"
    />
  </v-list-group>
</template>

<script lang="ts">
import { ref, onMounted } from 'vue';
import SubItemContent from './SubItemContent.vue';
import ItemContent from './ItemContent.vue';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    isActiveLink: {
      type: Function,
      required: true,
    },
  },
  components: {
    SubItemContent,
    ItemContent,
  },
  setup(props) {
    const isGroupParentHovered = ref(false);
    const isParentExpanded = ref(false);

    onMounted(() => {
      isParentExpanded.value = isParentActiveLink(props.item.items, window.location.pathname);
    });

    const isParentActiveLink = (items: any[], currentPath: string) => {
      let isActive = false;
      items?.forEach(item => {
        item.activePaths?.forEach(path => {
          if (currentPath.startsWith(path)) isActive = true;
        });
      });
      return isActive;
    };

    const onGroupParentHover = isHovered => {
      isGroupParentHovered.value = isHovered;
    };

    return { isGroupParentHovered, isParentExpanded, onGroupParentHover, isParentActiveLink };
  },
};
</script>

<style scoped>
/* Style for active elements in the nav */
.active-parent-subitems >>> .v-list-item--active::before {
  opacity: 0.12 !important;
  background: linear-gradient(to right, rgba(66, 55, 255, 0.75) 0%, transparent 95%);
  background-color: transparent;
}

.active-parent-subitems >>> .v-list-group__header .v-list-item__content .v-list-item__title {
  font-size: 16px !important;
  color: var(--v-primary-base) !important;
  font-weight: 700 !important;
}

/* Style for hover elements in the nav */
::v-deep .v-list-item--link:hover::before {
  background-color: transparent !important;
}

.hover-item ::v-deep .v-list-group__header::before {
  background: linear-gradient(to right, rgba(66, 55, 255, 1) 0%, transparent 95%);
  background-color: transparent;
}
/* Text color when hover */
.hover-item >>> .v-list-item__content .hover-item-content__title {
  font-size: 16px !important;
  color: var(--v-primary-base) !important;
}
</style>
