var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.isTest
    ? _c("v-list-item-icon", { staticClass: "mx-0 my-auto align-center" }, [
        _c("div", { staticClass: "test-badge-wrapper" }, [
          _c("span", { staticClass: "test-badge text-body-3-medium" }, [
            _vm._v("TEST"),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }