import { inject } from 'vue';
import { Component, Emit, Inject, Vue, Watch } from 'vue-property-decorator';
import axios, { AxiosInstance } from 'axios';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'vue-chartjs';

import SButtonBack from '@/components/stemdo-components/s-button-back/SButtonBack.vue';
import SPopUp from '@/components/stemdo-components/s-pop-up/SPopUp.vue';

import ResourceService from '@/shared/ResourceService.service';
import { BCard, BCardBody, BModal } from 'bootstrap-vue';
import { IClient } from '@/shared/model/client.model';
import { ITeamRequest, TeamRequest } from '@/shared/model/team-request.model';
import { IVertical } from '@/shared/model/vertical.model';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import TeamRequestService from '@/entities/team-request/team-request.service';
import { ITeamRequestProposal, TeamRequestProposal } from '@/shared/model/team-request-proposal.model';
import ClientRateService from '@/entities/client-rate/client-rate.service';
import { useStore } from '@/plugins/vuex';
import { mapGetters } from 'vuex';
import StemdoerDetail from '../../sections/explore/stemdoer-detail/StemdoerDetail.vue';
import { IRate } from '@/shared/model/rate.model';
import { getAuthorityEnum } from '@/shared/security/authority';
import AuthToken from '@/shared/security/AuthToken';
import { useI18N } from '@/plugins/i18n';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import StemdoerAvatar from '@/sections/explore/stemdoer-avatar/StemdoerAvatar.vue';
import { IStemdoerRate } from '@/shared/model/stemdoer-rate.model';

ChartJS.register(PointElement, LineElement, RadialLinearScale, Filler, Tooltip, Legend);

@Component({
  computed: {
    ...mapGetters({
      cartTotalItems: 'cartStore/cartTotalItems',
    }),
  },
  watch: {
    cartTotalItems(newVal) {
      if (newVal == 0 && this.arrayStemdoers.length == 0) {
        this.redirectToExplore();
      }
    },
  },
  components: {
    'b-card': BCard,
    'b-cardBody': BCardBody,
    Radar: Radar,
    SButtonBack,
    StemdoerDetail,
    SPopUp,
    StemdoerAvatar,
  },
  data() {
    return {
      selectedClientId: null,
      totalHourlyCost: 0,
      dialog: false,
      proposalUrlId: null,
      locationTeam: '',
      arrayStemdoers: [],
      startDate: '',
      finishDate: '',
      activeSelected: '',
      teamRequestName: '',
      teamRequestDescription: '',
      modalTitle: '',
      modalFinish: '',
      people: {
        type: Object,
      },
      peopleImg: {
        type: String,
      },
      myData: {
        idClient: {
          type: String,
        },
      },
      RequestStatus,
    };
  },
})
export default class TeamSelected extends Vue {
  [x: string]: any;
  @Inject('resourceService') private resourceService: ResourceService;
  @Inject('teamRequestService') private teamRequestService: TeamRequestService;
  @Inject('clientRateService') private clientRateService: ClientRateService;

  public store = useStore();
  public i18n = useI18N();
  private axios: AxiosInstance;
  public rate: IRate[] = [];
  public avgSkills = [];
  public techName = [];
  public selectedClientId = '';
  public fechaActual = new Date();
  public endDateInput = new Date(this.fechaActual.getFullYear(), 11, 32)
    .toISOString()
    .slice(0, 10)
    .match(/\d{4}-\d{2}-\d{2}/)[0];

  public getCientRates = async () => {
    const res = await this.clientRateService.retrieveClientRates();
    const data = res.data;
    data.forEach(item => {
      this.rate.push(item);
    });
  };

  public options = {
    plugins: {
      legend: {
        display: false,
        title: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      r: {
        ticks: {
          display: false,
        },
        pointLabels: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 7,
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
    },
  };
  public data = {
    menuStartDate: false,
    menuEndDate: false,
    loader: null,
    loading: false,
    clipboard: false,
    dialog: false,
    errorPopUp: false,
    labels: [],
    datasets: [
      {
        label: 'Media  de  Skills',
        backgroundColor: 'rgba(179,181,198,0.2)',
        borderColor: 'rgba(179,181,198,1)',
        pointBackgroundColor: 'rgba(179,181,198,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: [],
      },
    ],
  };

  public loader = null;
  public loading = false;
  public menuStartDate = false;
  public menuEndDate = false;
  public stemdoer;
  public myArrayIds = [];
  public stemdoerSeniorities = [];
  public teamRequestStatus: RequestStatus = null;
  public totalHourlyCost: any;
  public totalDailyCost = 0;
  public arrayStemdoers: any = [];
  public isArrayStemdoersComplete = false;
  public fpStemdoer = '';
  public stemdoerShowBadge = {};
  public listID = '';
  public imgList = {};
  public stemdoerSkills;
  public footp = '';
  public isSaving = false;
  public loaded = false;
  public selectedPerson = '';
  public personId = '';
  public clientId = '';
  public proposalId = '';
  public proposalUrl = '';
  public baseUrl = window.location.origin;
  public isAdminOrGestor = false;
  public isClient = false;
  public nameStemdoer = '';
  public nameSelected = '';
  public ordenTech = [];
  public srcIconProfile = '/data/stemdoers_avatars/';
  public listaAux = {};
  public miDiccionario = {};
  public finalDic = {};
  public firstXPairs: any;
  public what = 0;
  public hueRotate: number;
  public locationTeam = '';
  public isButtonDisabled = false;
  public selectedLength = 0;
  public chartKey = 0;
  public isLoaded = false;
  public badgesSkills = [];
  public errorPopUp = false;

  public teamRequestDescription: string;
  public teamRequestName: string;
  public initDate: any = new Date().toISOString().slice(0, 10);
  public startDate: any;
  public finishDate: any;
  public formattedStartDate: any;
  public formattedEndDate: any;
  public endDate: any = new Date(new Date().getFullYear(), 11, 32).toISOString().slice(0, 10);
  public maxEndDate: any = new Date(new Date().getFullYear() + 1, 11, 31).toISOString().slice(0, 10);
  public proposalUrlId: string;
  public proposalCreatedAt: any;
  public dialog = false;
  public showEndDate = false;

  public client: IClient;
  public teamRequest: ITeamRequest = new TeamRequest();
  public teamRequestProposal: ITeamRequestProposal = new TeamRequestProposal();
  public vertical: IVertical;
  public wrongDate = false;
  public arrayTech = [];
  public textAux = [];
  public initDateCalendar = window.location.search.split('&')[0].split('=')[1];
  public activeSelected: string;

  public created() {
    this.proposalUrlId = this.$route.params.id;
    this.startingMethod();
  }

  public async startingMethod() {
    this.getCientRates();
    await this.loadStemdoers();
    this.stemdoerFootPrint();
    this.getData();
  }

  @Watch('loader')
  onLoaderChanged(newVal: any, oldVal: any) {
    const l = newVal;
    this[l] = !this[l];

    setTimeout(() => (this[l] = false), 3000);

    this.loader = null;
  }

  public closeDialog(): void {
    (<any>this.$refs.finishBlock).hide();
    this.redirectToExplore();
  }

  public async redirectToExplore(): Promise<void> {
    try {
      await this.$router.push('/explore');
    } catch (error) {}
  }

  public startLoading() {
    this.loading = true;
    this.generatePDFListStemdoers();
    setTimeout(() => {
      this.loading = false;
    }, 5000);
  }

  public checkWrongDate() {
    const isInitDate = this.startDate && this.startDate !== '';
    const isEndDate = this.finishDate && this.finishDate !== '';
    const isProposal = !this.isButtonDisabled && this.proposalId !== '' && this.proposalUrl !== '';
    const isLengthDifferent = this.arrayStemdoers.length !== this.selectedLength;
    const today = new Date().toISOString().slice(0, 10);
    this.formatDateLanguage();

    if (isInitDate) {
      this.wrongDate = this.startDate === today || (isEndDate && this.finishDate < this.startDate);
    } else {
      this.wrongDate = true;
    }

    this.isButtonDisabled = (this.wrongDate || isProposal) && !isLengthDifferent;
    this.proposalId = '';
  }

  public formatDateLanguage() {
    const today = new Date().toISOString().slice(0, 10);

    if (this.startDate == null || this.startDate === today) {
      this.startDate = today;
      this.formattedStartDate = this.$t('label.teams.selected.initDateTip');
    } else {
      this.formattedStartDate = this.i18n.d(new Date(this.startDate), 'long').replace(/ de /g, ' ');
    }
    if (this.finishDate == null) {
      this.showEndDate = false;
      this.formattedEndDate = this.$t('label.teams.selected.endDateTip');
    } else {
      this.formattedEndDate = this.i18n.d(new Date(this.finishDate), 'long').replace(/ de /g, ' ');
    }
  }

  public deleteStemdoer(stemdoerId) {
    const isProposal = this.proposalUrlId != '';
    if (this.arrayStemdoers.length > 0) {
      const stemdoerToDelete = this.arrayStemdoers.find(e => e.id == stemdoerId);
      if (stemdoerToDelete) {
        const stemdoIdToDelete = stemdoerToDelete.stemdoId;
        if (!isProposal) {
          this.store.commit('cartStore/removeStemdoer', stemdoerToDelete.id);
        }
        this.arrayStemdoers = this.arrayStemdoers.filter(e => e.id != stemdoerId);
        this.myArrayIds = this.myArrayIds.filter(id => id != stemdoerId);
        let stemdoIdArray = this.listID.split(',');
        stemdoIdArray = stemdoIdArray.filter(id => id != stemdoIdToDelete);
        this.listID = stemdoIdArray.join(',');
        this.listID = this.listID + ',';
      }
      this.stemdoerFootPrint();
      this.getTempTotal();
      this.checkWrongDate();
      if (this.myArrayIds.length > 0) {
        this.fillAndAverageDictionary();
      }
      this.newDataAfterStemdoerRemoval();
    }
    if (isProposal && this.arrayStemdoers.length == 0) {
      this.redirectToExplore();
    }
  }

  public footprintCircle(a) {
    const footp = a;
    const circumference = 2 * Math.PI * 67;
    let strokeDasharray, offset;
    if (footp > 100) {
      strokeDasharray = `${circumference} ${circumference}`;
      offset = 0;
    } else {
      strokeDasharray = `${circumference} ${circumference}`;
      offset = ((100 - Number(footp)) / 100) * circumference;
      offset = Math.min(offset, circumference);
    }

    return {
      'stroke-dasharray': strokeDasharray,
      'stroke-dashoffset': offset,
    };
  }

  public generatePDF() {
    const modal = this.$refs.modalVertical as BModal;
    const myData = {
      locale: this.$i18n.locale,
    };
    const myJSON = JSON.stringify(myData);
    this.resourceService.generatePDF(this.$data.people.id, myJSON);
    modal.hide();
  }

  public getClient() {
    const client = this.store.getters['client'];
    this.client = client;
    if (this.proposalUrlId == '') {
      this.getTempTotal();
    }
  }

  public generatePDFListStemdoers() {
    if (sessionStorage.getItem('loginName') != 'admin') this.getClient();

    let listIdMongo = '';
    for (let i = 0; i < this.arrayStemdoers.length; i++) {
      listIdMongo += this.arrayStemdoers[i].id + ',';
    }
    listIdMongo = listIdMongo.substring(0, listIdMongo.length - 1);

    const myData = {
      isTeam: true,
      footp: this.footp ?? 6,
      dataRadar: this.data,
      stemdoerShowBadge: this.stemdoerShowBadge,
      ratesList: {},
      initDate: new Date(this.initDate).toLocaleDateString(),
      endDate: new Date(this.endDate).toLocaleDateString(),
      locale: this.$i18n.locale,
      paramId: '',
      idClient: this.client.id,
      nameClient: this.client
        ? this.client.name.charAt(0).toLocaleUpperCase() +
          this.client.name.slice(1) +
          ' ' +
          this.client.surname.substring(0, 1).toLocaleUpperCase() +
          '.'
        : '',
    };
    const myJSON = JSON.stringify(myData);

    this.resourceService.generatePDF(listIdMongo, myJSON);
  }

  public fillListAndImages(res): void {
    this.arrayStemdoers.push(res);
    this.badgesFormatter(res);

    this.resourceService
      .findImg(res.stemdoId)
      .then(img => {
        this.imgList[res.stemdoId] = img;
        this.bestSkills(res.technologyScores, res.stemdoId);
        this.$mount();
      })
      .catch(err => {
        console.error(err);
      });
  }

  public fillAndAverageDictionary() {
    const sortedTechScores = [...this.arrayStemdoers[0].technologyScores].sort((a, b) => a.idTechnology - b.idTechnology);

    for (const techScore of sortedTechScores) {
      this.miDiccionario[techScore.idTechnology] = 0;
      if (!this.ordenTech.includes(techScore.idTechnology)) {
        const technology = this.$t(`stemdoPlatformApp.technology.list.${techScore.idTechnology}`);
        this.ordenTech.push(technology);
      }
    }

    for (const stemdoer of this.arrayStemdoers) {
      for (const techScore of stemdoer.technologyScores) {
        this.miDiccionario[techScore.idTechnology] += techScore.score;
      }
    }

    for (const key in this.miDiccionario) {
      if (this.miDiccionario.hasOwnProperty(key)) {
        this.miDiccionario[key] = this.miDiccionario[key] / this.arrayStemdoers.length;
      }
    }
  }

  public onModalShow() {
    requestAnimationFrame(() => {
      document.body.style.paddingRight = '';
    });
  }

  public executeBlock(): void {
    this.checkWrongDate();
    this.$refs.blockEntity ? (this.$refs.blockEntity as any).show() : null;
  }

  public finishBlockTeam(): void {
    this.modalTitle = this.$t('label.teams.selected.titleFinishBlock');
    this.modalFinish = this.$t('label.teams.selected.validationInfo');
    this.$refs.finishBlock ? (this.$refs.finishBlock as any).show() : null;
  }

  public finishPrepareProposal(): void {
    this.modalTitle = this.$t('label.teams.selected.titleFinishProposal');
    this.modalFinish = this.$t('label.teams.selected.validationInfoProposal');
    this.$refs.finishBlock ? (this.$refs.finishBlock as any).show() : null;
  }

  public prepareProposal(): void {
    this.checkWrongDate();
    this.$refs.prepareProposal ? (this.$refs.prepareProposal as any).show() : null;
  }

  public bestSkills(skills, stemdo) {
    const lista = [];

    this.stemdoerSkills = '';

    skills.forEach(skillObj => {
      const technology = this.$t(`stemdoPlatformApp.technology.list.${skillObj.idTechnology}`).toString();
      const skill = skillObj.score.toString();

      lista.push({
        id: skillObj.idTechnology,
        technology: technology,
        skill: skill,
      });

      if (typeof this.listaAux[stemdo] === 'undefined') {
        Vue.set(this.listaAux, stemdo, {});
      }
      Vue.set(this.listaAux[stemdo], technology, skill);
    });

    lista.sort((a, b) => b.skill - a.skill || a.id - b.id);

    const sortedTechnologies = lista.map(({ technology, skill }) => ({ technology, skill }));

    const top4Technologies = sortedTechnologies.slice(0, 10);

    this.stemdoerShowBadge[stemdo] = top4Technologies;
  }

  public async loadStemdoers() {
    let stemdoers: IStemdoer[] = undefined;
    if (this.proposalUrlId != null && this.proposalUrlId != undefined) {
      const teamRequestProposalRes = await this.getTeamRequestById(this.proposalUrlId);
      this.teamRequestProposal.stemdoerRates = teamRequestProposalRes.stemdoerRates as IStemdoerRate[];
      stemdoers = teamRequestProposalRes.stemdoerRates.map(stemdoerRate => stemdoerRate.stemdoer);

      const stemdoerSelected = stemdoers.map(stemdoer => stemdoer.id);
      const stemdoerIdSelect = stemdoers.map(stemdoer => stemdoer.stemdoId).join(',');
      this.startDate = teamRequestProposalRes.initDate;
      this.finishDate = teamRequestProposalRes.endDate;
      this.formatDateLanguage();
      this.teamRequestName = teamRequestProposalRes.name;
      this.teamRequestDescription = teamRequestProposalRes.jobDescription;
      this.myArrayIds = stemdoerSelected;
      this.listID = stemdoerIdSelect + ',';
      this.totalHourlyCost = teamRequestProposalRes.hourlyRate;
      this.totalDailyCost = this.totalHourlyCost * 8;
      this.showEndDate = this.finishDate != null ? true : false;
      this.proposalCreatedAt = teamRequestProposalRes.createdAt;
      this.teamRequestStatus = teamRequestProposalRes.status;
    } else {
      stemdoers = this.store.getters['cartStore/cartItems'];
      const stemdoerIdSelect = stemdoers.map(stemdoer => stemdoer.stemdoId);
      this.listID = stemdoerIdSelect.join() + ',';
      this.startDate = new Date().toISOString().slice(0, 10);
      this.finishDate = null;
      this.formatDateLanguage();
      this.myArrayIds = stemdoers.map(stemdoer => stemdoer.id);
      this.proposalUrlId = '';
    }

    for (const stemdoer of stemdoers) {
      this.fillListAndImages(stemdoer);
      this.locationTeam = 'Stemdo';
    }

    this.fillAndAverageDictionary();
    this.sortAndPrepareData();
    this.loaded = true;
    this.selectedLength = this.arrayStemdoers.length;
    await this.$mount();
  }

  public approveTeamRequest() {
    const teamRequestId = this.$route.params.id;
    this.teamRequestService
      .changeStatus(teamRequestId, RequestStatus.ON_GOING.toString())
      .then(() => {
        this.modalTitle = this.$t('requests.info-box.approved.modal-title');
        this.modalFinish = this.$t('requests.info-box.approved.modal-msg');
        this.$refs.finishBlock ? (this.$refs.finishBlock as any).show() : null;
      })
      .catch(error => {
        this.errorPopUp = true;
        console.error(error);
      });
  }

  public stemdoerFootPrint() {
    this.listID = this.listID.substring(0, this.listID.length - 1);
    this.resourceService
      .getFootPrint(this.listID)
      .then(footprint => {
        this.footp = (Math.floor(Number(footprint) * 10) / 10).toString();
        this.$mount();
      })
      .catch(err => {
        this.footp = (this.listID.split(',').length * 6).toString();
        console.error(err.response.data.detail);
      });
  }

  public sortAndPrepareData() {
    const filteredEntries = Object.entries(this.miDiccionario).filter(([key, value]) => !isNaN(Number(value)));
    const sortedDict = Object.fromEntries(
      filteredEntries.sort((a, b) => {
        if (b[1] === a[1]) {
          const aIndex = this.ordenTech.indexOf(a[0]);
          const bIndex = this.ordenTech.indexOf(b[0]);
          return aIndex - bIndex;
        }
        return +b[1] - +a[1];
      })
    );
    const firstXPairs = Object.fromEntries(Object.entries(sortedDict).slice(0, 5));
    for (const key in firstXPairs) {
      if (typeof firstXPairs[key] === 'number') {
        firstXPairs[key] = Math.round(firstXPairs[key] as number);
      }
    }
    let u = 0;
    this.data.datasets[0].data.splice(0, this.data.datasets[0].data.length);
    this.data.labels = [];
    for (const clave in firstXPairs) {
      const technology = this.$t(`stemdoPlatformApp.technology.list.${clave}`).toString();
      this.data.labels.push(technology);
      const dataValue = isNaN(Number(firstXPairs[clave])) ? 0 : Number(firstXPairs[clave]);
      this.data.datasets[0].data.push(dataValue);
      u++;
    }
  }

  public newDataAfterStemdoerRemoval() {
    const sortedDict = Object.fromEntries(
      Object.entries(this.miDiccionario)
        .filter(([key, value]) => !isNaN(value as number))
        .sort((a, b) => {
          if (b[1] === a[1]) {
            const aIndex = this.ordenTech.indexOf(a[0]);
            const bIndex = this.ordenTech.indexOf(b[0]);
            return aIndex - bIndex;
          }
          return +b[1] - +a[1];
        })
    );
    const firstXPairs = Object.fromEntries(Object.entries(sortedDict).slice(0, 5));
    for (const key in firstXPairs) {
      if (typeof firstXPairs[key] === 'number') {
        firstXPairs[key] = Math.round(firstXPairs[key] as number);
      }
    }
    const newData = { ...this.data };
    newData.datasets[0].data = [];
    newData.labels = [];
    const labelsSet = new Set(newData.labels);
    for (const clave in firstXPairs) {
      if (!labelsSet.has(clave) && newData.labels.length < 5) {
        const technology = this.$t(`stemdoPlatformApp.technology.list.${clave}`).toString();
        newData.labels.push(technology);
        const dataValue = isNaN(firstXPairs[clave] as number) ? 1 : (firstXPairs[clave] as number);
        newData.datasets[0].data.push(dataValue);
        labelsSet.add(technology);
      }
    }
    this.data = newData;
    this.chartKey++;
  }

  public blockProposal() {
    if (!this.isButtonDisabled) {
      this.errorPopUp = false;
      const proposalJobDescription = (this.$refs.proposalJobDescription as HTMLTextAreaElement).value;
      const proposalName = (this.$refs.proposalName as HTMLTextAreaElement).value;
      this.clientId = this.isClient && this.client.id ? this.client.id : this.isAdminOrGestor && this.selectedClientId;
      this.initDate = this.startDate;
      this.endDate = this.finishDate;

      this.teamRequestProposal.createdAt = this.proposalCreatedAt;
      this.teamRequestProposal.stemdoers = this.arrayStemdoers;
      this.teamRequestProposal.name = proposalName;
      this.teamRequestProposal.jobDescription = proposalJobDescription;
      this.teamRequestProposal.initDate = this.initDate;
      this.teamRequestProposal.endDate = this.endDate;
      this.teamRequestProposal.client = { id: this.client.id };
      this.teamRequestProposal.status = RequestStatus.SAVED;
      this.teamRequestProposal.validation = true;
      this.teamRequestProposal.hourlyRate = this.totalHourlyCost;

      let isCreating = false;
      if (this.proposalId == '' && this.proposalUrlId == '' && !isCreating) {
        isCreating = true;
        this.teamRequestService
          .create(this.teamRequestProposal)
          .then(param => {
            this.proposalId = param.id;
            this.isButtonDisabled = true;
            this.finishPrepareProposal();
          })
          .then(() => {
            if (this.proposalId != '') {
              this.proposalUrl = `${this.baseUrl}/team-requests/${this.proposalId}`;
              const message = this.$t('label.teams.successTrp');
              this.selectedLength = this.arrayStemdoers.length;
              this.store.commit('cartStore/clearCart');
            }
          })
          .catch(error => {
            this.isSaving = false;
            this.errorPopUp = true;
            console.error(error);
          })
          .finally(() => {
            isCreating = false;
          });
      } else if (this.proposalUrlId != '') {
        this.teamRequestProposal.id = this.proposalUrlId;
        this.teamRequestService
          .partialUpdate({ id: this.proposalUrlId, ...this.teamRequestProposal })
          .then(() => {
            this.isButtonDisabled = true;
            this.selectedLength = this.arrayStemdoers.length;
            this.finishPrepareProposal();
          })
          .catch(error => {
            this.isSaving = false;
            this.errorPopUp = true;
            console.error(error);
          });
      }
      (<any>this.$refs.prepareProposal).hide();
    }
  }

  public async copyToClipboard() {
    try {
      await navigator.clipboard.writeText(this.proposalUrl);
      this.data.clipboard = true;
      setTimeout(() => {
        this.data.clipboard = false;
      }, 3000);
    } catch (err) {
      console.error('Error:', err);
    }
  }

  public blockTeam() {
    if ((this.isButtonDisabled && this.proposalId != '') || !this.isButtonDisabled) {
      this.errorPopUp = false;
      this.initDate = this.startDate;
      this.endDate = this.finishDate;
      this.vertical = this.arrayStemdoers[0].verticals[0];

      const ratesList = Array.from(this.arrayStemdoers.values()).reduce((rates, stemdoerRates: IStemdoer) => {
        rates[stemdoerRates.id] = stemdoerRates.rate;
        return rates;
      }, {});
      const myData = {
        footp: this.footp['footprint_team'] ?? 6,
        dataRadar: this.data,
        stemdoerShowBadge: this.stemdoerShowBadge,
        initDate: new Date(this.initDate).toLocaleDateString(),
        endDate: new Date(this.endDate).toLocaleDateString(),
        locale: this.$i18n.locale,
        ratesList: ratesList,
        paramId: '',
        idClient: this.client.id,
        nameClient: this.client
          ? this.client.name.charAt(0).toLocaleUpperCase() +
            this.client.name.slice(1) +
            ' ' +
            this.client.surname.substring(0, 1).toLocaleUpperCase() +
            '.'
          : '',
        emailClient: this.client.email,
        nameStemdoers: [],
      };

      const myJSON = JSON.stringify(myData);
      const jobDescription = (this.$refs.jobDescription as HTMLTextAreaElement).value;
      const teamRequestName = (this.$refs.teamRequestName as HTMLTextAreaElement).value;
      // Crear equipo
      this.teamRequest.name = teamRequestName;
      this.teamRequest.client = { id: this.client.id };
      this.teamRequest.vertical = this.vertical;
      this.teamRequest.stemdoers = this.arrayStemdoers;
      this.teamRequest.status = RequestStatus.PENDING;
      this.teamRequest.initDate = this.initDate;
      this.teamRequest.endDate = this.endDate;
      this.teamRequest.createdAt = new Date();
      this.teamRequest.paramsUrl = myJSON;
      this.teamRequest.jobDescription = jobDescription;
      this.teamRequest.hourlyRate = 0;

      const isProposal = this.proposalUrlId != '';

      const processRequestEmail = (teamRequestId, clientEmail) => {
        myData.paramId = teamRequestId;
        this.axios = axios;

        let listIdMongo = '';
        for (let i = 0; i < this.arrayStemdoers.length; i++) {
          listIdMongo += this.arrayStemdoers[i].id + ',';
          myData.nameStemdoers.push(
            this.arrayStemdoers[i].name.charAt(0).toLocaleUpperCase() +
              this.arrayStemdoers[i].name.slice(1) +
              ' ' +
              this.arrayStemdoers[i].surname.charAt(0).toLocaleUpperCase() +
              this.arrayStemdoers[i].surname.slice(1)
          );
        }
        listIdMongo = listIdMongo.substring(0, listIdMongo.length - 1);
        const myJSON = JSON.stringify(myData);
        this.axios
          .get(`api/import/pdf?email=${clientEmail}`, {
            params: {
              listIdMongo: listIdMongo,
              dataNecessary: myJSON,
            },
          })
          .catch(err => {
            console.error(err);
          });
      };
      let isCreating = false;
      if (!isProposal && !isCreating) {
        isCreating = true;
        this.teamRequestService
          .create(this.teamRequest)
          .then(teamRequest => {
            processRequestEmail(teamRequest.id, teamRequest.client.email);
            this.finishBlockTeam();
          })
          .catch(error => {
            this.isSaving = false;
            this.errorPopUp = true;
            console.error(error);
          })
          .finally(() => {
            isCreating = false;
          });
        this.store.commit('cartStore/clearCart');
      } else {
        this.teamRequest.createdAt = new Date();
        this.teamRequest.id = this.proposalUrlId;
        this.teamRequest.status = RequestStatus.SAVED;
        this.teamRequest.hourlyRate = this.totalHourlyCost;
        this.teamRequest.initDate = this.startDate;
        this.teamRequest.endDate = this.finishDate;
        this.teamRequest.stemdoerRates = this.arrayStemdoers;
        this.teamRequestService
          .blockAndPartialUpdate({ ...this.teamRequest })
          .then(teamRequest => {
            processRequestEmail(teamRequest.id, teamRequest.client.email);
            this.finishBlockTeam();
          })
          .catch(error => {
            this.isSaving = false;
            this.errorPopUp = true;
            console.error(error);
          });
      }
      (<any>this.$refs.blockEntity).hide();
    }
  }

  public async getRole() {
    try {
      const isGestor = this.checkRole('ROLE_ADMIN') || this.checkRole('ROLE_GESTOR');
      const isClient = await this.checkRole('ROLE_CLIENT');

      this.isAdminOrGestor = isGestor;
      this.isClient = isClient;
    } catch (error) {
      console.error(error);
    }
  }
  public getData() {
    this.getRole();
    this.initDate = this.startDate;
    this.endDate = this.finishDate;
    this.getClient();
  }

  public hasAnyAuthority(authority: string): boolean {
    const auth = getAuthorityEnum(authority);
    return AuthToken.hasAnyAuthority([auth]);
  }
  public checkRole(role: string): boolean {
    const auth = getAuthorityEnum(role);
    return AuthToken.hasAnyAuthority([auth]);
  }

  public getTempTotal() {
    const isProposal = this.proposalUrlId != '';
    const stemdoerSource = isProposal ? this.arrayStemdoers : this.store.getters['cartStore/cartItems'];
    const stemdoerSeniorities = stemdoerSource.map(stemdoer => stemdoer.seniority);

    this.teamRequestService
      .calculateTempTotal(this.client.id, stemdoerSeniorities)
      .then(res => {
        this.totalHourlyCost = res;
        this.totalDailyCost = this.totalHourlyCost * 8;
      })
      .catch(err => {
        console.error(err);
      });
  }

  public async getTeamRequestById(id) {
    const teamRequestProposalRes = await this.teamRequestService.find(id);
    return teamRequestProposalRes;
  }

  public formatBadges(technologyScores) {
    let badgesSkills = technologyScores.slice(0, 10).map(tech => ({
      id: tech.idTechnology,
      technology: this.$t(`stemdoPlatformApp.technology.list.${tech.idTechnology}`),
      skill: tech.score,
    }));

    badgesSkills.sort((a, b) => b.skill - a.skill || a.id - b.id);
    badgesSkills = badgesSkills.map(({ id, technology, skill }) => ({ id, technology, skill }));

    return badgesSkills;
  }

  public badgesFormatter(stemdoer) {
    stemdoer.badgesSkills = this.formatBadges(stemdoer.technologyScores);
    this.isLoaded = true;
  }

  public closeDetailDialog() {
    this.dialog = false;
    this.resetOverflow();
  }

  public resetOverflow() {
    document.documentElement.style.overflow = 'auto';
  }

  public closePopUp() {
    this.errorPopUp = false;
  }

  public toggleEndDateInput() {
    this.showEndDate = !this.showEndDate;

    if (this.showEndDate) {
      this.finishDate = this.endDateInput;
      this.checkWrongDate();
    } else {
      this.clearFinishDate();
    }
  }

  public clearFinishDate() {
    this.finishDate = null;
    this.showEndDate = false;
    this.menuEndDate = false;
  }
}
