import { inject, onMounted, ref } from 'vue';
import TranslationService from '@/locale/translation.service';
import { useI18N } from '@/plugins/i18n';

export default {
  setup() {
    const i18n = useI18N();
    const translationService: TranslationService = inject('translationService');
    const currentLanguage = ref(undefined);

    onMounted(() => {
      currentLanguage.value = i18n.locale;
    });

    const languages = [
      { id: 'en', desc: 'English', svg: 'english-uk-lang' },
      { id: 'es', desc: 'Español', svg: 'spanish-es-lang' },
    ];

    const changeLanguage = (lang: string) => {
      translationService.refreshTranslation(lang);
    };

    return { currentLanguage, languages, changeLanguage };
  },
};
