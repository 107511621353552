var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "btn-my-team" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "btn-myteam",
          attrs: {
            outlined: "",
            disabled: !(_vm.cartTotalItems > 0 && _vm.isClientSelected),
          },
          on: {
            click: function ($event) {
              return _vm.goToCheckout()
            },
          },
        },
        [
          _vm.cartTotalItems != 0
            ? _c(
                "v-badge",
                {
                  staticStyle: { transform: "scale(0.7)" },
                  attrs: {
                    bottom: "",
                    left: "",
                    "offset-x": "10",
                    "offset-y": "10",
                  },
                },
                [
                  _c("span", { attrs: { slot: "badge" }, slot: "badge" }, [
                    _vm._v(_vm._s(_vm.cartTotalItems)),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticStyle: { padding: "-0.5em", "margin-right": "0.5em" },
                    attrs: { src: "/content/svgs/team.svg", width: "20em" },
                  }),
                ]
              )
            : _c("img", {
                staticStyle: { padding: "-0.5em", "margin-right": "0.5em" },
                attrs: { src: "/content/svgs/team.svg", width: "20em" },
              }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "text-none",
              staticStyle: {
                "font-weight": "700",
                color: "#222226",
                "font-size": "16px",
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("explore.my-team").charAt(0).toUpperCase() +
                    _vm.$t("explore.my-team").slice(1)
                )
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-alert",
        {
          staticClass: "alert-box",
          attrs: {
            transition: "slide-x-transition",
            value: _vm.isAlertVisible,
            color: "white",
          },
        },
        [
          _c("div", { staticClass: "btn-exit" }, [
            _c("img", {
              staticStyle: { opacity: "0.8" },
              attrs: { src: "/content/svgs/close-tag.svg", width: "20px" },
              on: { click: () => (_vm.isAlertVisible = false) },
            }),
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0 ma-0 my-4",
                  staticStyle: { display: "flex" },
                  attrs: { cols: "2" },
                },
                [
                  _vm.lastStemdoer
                    ? _c("StemdoerAvatar", {
                        attrs: {
                          stemdoId: _vm.lastStemdoer.stemdoId,
                          size: "30px",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "10" } }, [
                _c("p", {
                  staticClass: "alert-text mt-2 ml-2",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("explore.alert.description", {
                        name: _vm.lastStemdoer?.name,
                      })
                    ),
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }