import { defineComponent, PropType, computed, onMounted, ref } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
    onChange: {
      type: Function as PropType<(newValue: number) => void>,
      required: true,
    },
  },
  setup(props) {
    const inputVal = ref<number>(0);
    let interval: NodeJS.Timeout | null = null;

    onMounted(() => {
      inputVal.value = props.value;
    });

    const incrementRate = () => {
      inputVal.value = Math.min(parseFloat((inputVal.value + 1).toFixed(2)), 999.99);
      validateRate();
    };

    const decrementRate = () => {
      inputVal.value = Math.max(parseFloat((inputVal.value - 1).toFixed(2)), 0);
      validateRate();
    };

    const validateRate = () => {
      if (typeof inputVal.value !== 'number') {
        inputVal.value = parseFloat(inputVal.value) || 0;
      }

      let value = inputVal.value.toString();
      value = value.replace(/[^0-9.]/g, '');
      const parts = value.split('.');

      if (parts[0].length > 3) {
        parts[0] = parts[0].slice(0, 3);
      }
      if (parts[1] && parts[1].length > 2) {
        parts[1] = parts[1].slice(0, 2);
      }

      let validatedRate = parseFloat(parts.join('.')) || 0;
      validatedRate = Math.min(validatedRate, 999.99);
      validatedRate = Math.max(validatedRate, 0);

      inputVal.value = validatedRate;
      props.onChange(validatedRate);
    };

    const startIncrement = () => {
      incrementRate();
      interval = setInterval(incrementRate, 100);
    };

    const startDecrement = () => {
      decrementRate();
      interval = setInterval(decrementRate, 100);
    };

    const stopChange = () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };

    return { inputVal, startIncrement, startDecrement, stopChange, validateRate };
  },
});
