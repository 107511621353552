import { defineComponent, inject, onMounted, ref, watch } from 'vue';
import ResourceService from '@/shared/ResourceService.service';

export default defineComponent({
  props: {
    stemdoId: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '90px',
    },
  },

  setup(props) {
    const resourceService: ResourceService = inject('resourceService');
    const stemdoerImg = ref('');
    const isBlobUrl = ref(false);
    const isLoaded = ref(false);

    onMounted(() => {
      retrieveImg(props.stemdoId);
    });

    const retrieveImg = (stemdoId: string) => {
      resourceService.findImg(stemdoId).then(res => {
        isBlobUrl.value = /^blob:/.test(res);
        stemdoerImg.value = res;
        isLoaded.value = true;
      });
    };

    watch(
      () => props.stemdoId,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          isLoaded.value = false;
          retrieveImg(newVal);
        }
      }
    );

    return {
      stemdoerImg,
      isLoaded,
      isBlobUrl,
    };
  },
});
