var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ hover }) {
          return [
            _c(
              "v-list-item",
              {
                class: [
                  "pr-0 pb-1 mb-0",
                  { "cursor-pointer": _vm.item.disable === false },
                ],
                on: {
                  click: function ($event) {
                    return _vm.goToPage(_vm.item.path, _vm.$route.path)
                  },
                },
              },
              [
                _c("div", { staticClass: "vl-wrapper mr-5" }, [
                  _c(
                    "div",
                    {
                      class: [
                        (_vm.isActiveLink(
                          _vm.item.activePaths,
                          _vm.$route.path
                        ) ||
                          hover) &&
                        _vm.item.disable === false
                          ? _vm.isLast
                            ? "vl-active-last"
                            : "vl-active"
                          : _vm.isLast
                          ? "vl-last"
                          : "vl",
                      ],
                    },
                    [_c("div", { staticClass: "middle" })]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "v-list-item-content",
                  { staticClass: "py-1" },
                  [
                    _c(
                      "v-list-item-title",
                      {
                        class: [
                          "py-1",
                          {
                            "text-body-1-bold text--text text--lighten-1":
                              _vm.isActiveLink(
                                _vm.item.activePaths,
                                _vm.$route.path
                              ),
                          },
                          {
                            "text-body-1-medium text--text text--lighten-1":
                              _vm.item.disable === false &&
                              !_vm.isActiveLink(
                                _vm.item.activePaths,
                                _vm.$route.path
                              ),
                          },
                          {
                            "text-body-1 text--text text--lighten-1 cursor-default":
                              _vm.item.disable === true,
                          },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.$t(_vm.item.title)))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.item.disable
                  ? _c(
                      "v-list-item-icon",
                      [
                        _c("v-img", {
                          attrs: { width: 24, src: "/content/svgs/lock.svg" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("BadgetTest", { attrs: { item: _vm.item } }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }