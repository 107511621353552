import { inject, onMounted } from 'vue';
import '@/shared/config/dayjs';
import MainLayout from '@/layouts/MainLayout.vue';
import TranslationService from './locale/translation.service';
import { useStore } from './plugins/vuex';

export default {
  components: {
    MainLayout,
  },
  setup() {
    const store = useStore();
    const translationService: TranslationService = inject('translationService');

    onMounted(() => {
      const currentLanguage = store.getters.currentLanguage;
      const availableLanguages: any = store.getters.languages;
      const navigatorLanguage = navigator.language;

      const newLanguage: string = Object.keys(availableLanguages).includes(navigatorLanguage) ? navigatorLanguage : currentLanguage;
      translationService.refreshTranslation(newLanguage);
    });
  },
};
