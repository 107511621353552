var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "requests-status" } },
    [
      _vm.status == _vm.RequestStatus.PENDING
        ? _c("div", [
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("requests.info-box.created.description")) +
                  "\n    "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.status == _vm.RequestStatus.VALIDATED
        ? _c(
            "div",
            [
              _c("p", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("requests.info-box.pending.description")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("SButton", {
                        staticStyle: { "font-size": "14px !important" },
                        attrs: {
                          btnStyle: "primary",
                          sText: "requests.info-box.pending.accept",
                          action: _vm.acceptGestorProposal,
                          disabled: _vm.isAcceptButtonDisabled,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("SButton", {
                        staticStyle: {
                          "font-size": "14px !important",
                          "background-color": "white",
                        },
                        attrs: {
                          btnStyle: "secondary",
                          sText: "requests.info-box.pending.send-msg",
                          action: _vm.sendMessage,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("SModalCentered", {
                attrs: {
                  isOpen: _vm.isAcceptModalOpen,
                  title: _vm.$t("requests.info-box.approved.modal-title"),
                  maxWidth: "500px",
                  closeModal: _vm.acceptCloseModal,
                  customProps: { bodyPaddingY: 0, fullWidthFooter: true },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "16px",
                                "font-weight": "400",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "requests.info-box.approved.modal-msg"
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c("sButton", {
                            attrs: {
                              btnStyle: "primary",
                              sText: "label.teams.selected.understood",
                              action: _vm.acceptCloseModal,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2780323551
                ),
              }),
            ],
            1
          )
        : _vm.status == _vm.RequestStatus.ON_GOING
        ? _c("div", [
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("requests.info-box.approved.description")) +
                  "\n    "
              ),
            ]),
          ])
        : _vm.status == _vm.RequestStatus.CANCELLED_BY_MANAGER
        ? _c("div", [
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("requests.info-box.rejected.description")) +
                  "\n    "
              ),
            ]),
          ])
        : _vm.status == _vm.RequestStatus.SAVED
        ? _c(
            "div",
            [
              _c("p", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("requests.info-box.saved.description")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("SButton", {
                        staticStyle: { "font-size": "14px !important" },
                        attrs: {
                          btnStyle: "primary",
                          sText: "requests.info-box.saved.accept",
                          disabled: _vm.isBlockTeamDisabled,
                          action: () =>
                            _vm.blockTeamRequest(_vm.teamRequest.id),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("SButton", {
                        staticStyle: {
                          "font-size": "14px !important",
                          "background-color": "white",
                        },
                        attrs: {
                          btnStyle: "secondary",
                          action: () =>
                            _vm.redirectBtnSavedtoEdit(_vm.teamRequest.id),
                          sText: "requests.info-box.saved.edit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          title: "requests.block-title",
          isOpen: _vm.isBlockModalOpen,
          closeModal: _vm.closeSuccesfullyBlockedTeamModal,
          maxWidth: "500px",
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("requests.block-message-textbody")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("sButton", {
                  attrs: {
                    btnStyle: "primary",
                    sText: "requests.cancel-button",
                    action: _vm.closeSuccesfullyBlockedTeamModal,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }