import { Component, Inject, Vue } from 'vue-property-decorator';
import AccountService from '@/account/account.service';
import { Authority, getAuthorityEnum } from '@/shared/security/authority';
import localStorageFacade, { StorageKey } from '@/shared/local-store-facade/LocalStoreFacade';
import AuthToken from '@/shared/security/AuthToken';
@Component
export default class EntitiesMenu extends Vue {
  @Inject('accountService') private accountService: () => AccountService;
  private hasAnyAuthorityValues = {};

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }
  public hasAnyAuthority(authority: string): boolean {
    const auth = getAuthorityEnum(authority);
    return AuthToken.hasAnyAuthority([auth]);
  }
}
