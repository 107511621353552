import { logout } from '../security/security';
import { setupAxiosInterceptors } from './axios-interceptor';

const configAxios = () => {
  setupAxiosInterceptors(
    error => {
      const status = error.status || error.response.status;
      if (status === 401) {
        logout();
      }
      console.log('Unauthorized!');
      return Promise.reject(error);
    },
    error => {
      console.log('Server error!');
      return Promise.reject(error);
    }
  );
};

export default configAxios;
