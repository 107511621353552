var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "m-0 px-8 pt-6",
      attrs: { id: "requests-wrapper", fluid: "" },
    },
    [
      _c(
        "v-row",
        {
          staticClass: "mb-4",
          staticStyle: { "align-items": "baseline" },
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { staticClass: "pa-0 ma-0", attrs: { cols: "2", fluid: "" } },
            [
              _c(
                "h3",
                { staticClass: "text-h3", attrs: { id: "title-page" } },
                [_vm._v(_vm._s(_vm.$t("requests.title-page")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _vm.teamRequestsDisplayed.length > 0 && !_vm.displaySkeleton
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("sButtonCard", {
                    attrs: {
                      listTeamRequest: _vm.teamRequestsDisplayed,
                      getStatusSelectedAndFilter:
                        _vm.getStatusSelectedAndFilter,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _vm.displaySkeleton
            ? [_c("RequestListSkeleton")]
            : _vm.teamRequestsDisplayed.length <= 0 && !_vm.displaySkeleton
            ? _c("NoContent")
            : [
                _c("RequestList", {
                  attrs: {
                    teamRequestDisplayed: _vm.teamRequestsFiltered,
                    openRequestDetailModal: _vm.openRequestDetailModal,
                    openCancelModal: _vm.openCancelModal,
                    statusFilterSelected: _vm.statusFilterSelected,
                  },
                }),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c("sModalHalfRight", {
        attrs: {
          isOpen: _vm.isRequestDetailModalOpen,
          closeModal: _vm.closeRequestDetailModal,
          maxWidth: "475px",
          title: _vm.$t("requests.detail-title"),
        },
        scopedSlots: _vm._u([
          {
            key: "title-append",
            fn: function () {
              return [
                _vm.proposalLink != ""
                  ? _c(
                      "span",
                      { staticClass: "ml-3" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              right: "",
                              "content-class": "primary tooltip-right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-img",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "link-button",
                                              attrs: {
                                                width: 22,
                                                src: "/content/svgs/link-2-outline1.svg",
                                              },
                                              on: {
                                                click: _vm.copyToClipboard,
                                              },
                                            },
                                            "v-img",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2401589373
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("requests.info-box.saved.share")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("RequestDetail", {
                  attrs: {
                    requestSelected: _vm.requestSelected,
                    closeRequestDetailModal: _vm.closeRequestDetailModal,
                    refreshTeamRequestData: _vm.refreshTeamRequestList,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          isOpen: _vm.isCancelModalOpen,
          title: _vm.$t("requests.cancel-title", {
            name: _vm.cancelModalTitleName,
          }),
          maxWidth: "500px",
          closeModal: _vm.closeCancelModal,
          customProps: { bodyPaddingY: 0, fullWidthFooter: true },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("requests.cancel-message-textbody")) +
                      "\n      "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("sButton", {
                  attrs: {
                    btnStyle: "primary",
                    sText: "requests.confirm-button",
                    action: _vm.cancelTeamRequest,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }