var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "p-0" },
    [
      _c(
        "v-row",
        { staticClass: "mb-2", attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c(
              "span",
              { staticClass: "text-body-2-bold text--text text--lighten-1" },
              [
                _vm._v(
                  _vm._s(_vm.$t("explore.more-filters.modal.filter.label-tech"))
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-6", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c("SAutocomplete", {
                attrs: {
                  placeholder:
                    "explore.more-filters.modal.filter.placeholder-tech",
                  items: _vm.techItems,
                  filterField: _vm.technologiesField,
                  action: _vm.changeTechFilter,
                  inputHeight: 45,
                  labelDisplaySelection: true,
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-2", attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c(
              "span",
              { staticClass: "text-body-2-bold text--text text--lighten-1" },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("explore.more-filters.modal.filter.label-competency")
                  )
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-6", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c("SAutocompleteMultipleRating", {
                attrs: {
                  placeholder:
                    "explore.more-filters.modal.filter.placeholder-competency",
                  items: _vm.compItems,
                  filterField: _vm.competenciesField,
                  action: _vm.changeCompFilter,
                  inputHeight: 45,
                  labelDisplaySelection: true,
                  label: "explore.filter.label-competency",
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-2", attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c(
              "span",
              { staticClass: "text-body-2-bold text--text text--lighten-1" },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("explore.more-filters.modal.filter.label-aptitudes")
                  )
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-6", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c("SAutocompleteMultiple", {
                attrs: {
                  placeholder:
                    "explore.more-filters.modal.filter.placeholder-aptitudes",
                  items: _vm.softSkillsItems,
                  filterField: _vm.softSkillField,
                  action: _vm.changeSoftSkillFilter,
                  inputHeight: 45,
                  labelDisplaySelection: true,
                  label: "explore.more-filters.modal.filter.label-aptitudes",
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-2", attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c(
              "span",
              { staticClass: "text-body-2-bold text--text text--lighten-1" },
              [
                _vm._v(
                  _vm._s(_vm.$t("explore.more-filters.modal.filter.label-cert"))
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-6", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c("SAutocompleteMultiple", {
                attrs: {
                  placeholder:
                    "explore.more-filters.modal.filter.placeholder-cert",
                  items: _vm.certItems,
                  filterField: _vm.certField,
                  action: _vm.changeCertFilter,
                  inputHeight: 45,
                  labelDisplaySelection: true,
                  label: "explore.more-filters.modal.filter.label-cert",
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-2", attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c(
              "span",
              { staticClass: "text-body-2-bold text--text text--lighten-1" },
              [
                _vm._v(
                  _vm._s(_vm.$t("explore.more-filters.modal.filter.label-rate"))
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-6", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c("SRangeSliderStepsRate", {
                attrs: {
                  filterField: _vm.rateField,
                  rates: _vm.rates,
                  action: _vm.changeRateFilter,
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-2", attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c(
              "span",
              { staticClass: "text-body-2-bold text--text text--lighten-1" },
              [
                _vm._v(
                  _vm._s(_vm.$t("explore.more-filters.modal.filter.label-lang"))
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c("CheckboxEnglish", {
                attrs: {
                  filterField: _vm.langField,
                  action: _vm.changeLangFilter,
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }