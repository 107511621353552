import { useRouter } from '@/plugins/router';
import { logout } from '@/shared/security/security';

export default {
  setup() {
    const router = useRouter();

    const onClick = () => {
      logout();
      router.push('/login');
    };

    return { onClick };
  },
};
