import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import TeamRequestService from '@/entities/team-request/team-request.service';
import RequestsGestorList from '@/sections/requests-gestor/request-list/RequestsGestorList.vue';

import { ITeamRequest } from '@/shared/model/team-request.model';
import RequestListSkeleton from '../requests/request-list-skeleton/RequestListSkeleton.vue';

export default defineComponent({
  components: {
    RequestsGestorList,
    RequestListSkeleton,
  },
  setup() {
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const teamRequestFiltered = ref<ITeamRequest[]>([]);
    const isLoading = ref(true);
    const displaySkeleton = computed(() => {
      return isLoading.value || (teamRequestFiltered.value.length === 0 && isLoading.value);
    });

    onMounted(() => {
      retrieveAllRequestsByManager();
    });

    const retrieveAllRequestsByManager = () => {
      isLoading.value = true;
      teamRequestService
        .getAllRequestsByManager()
        .then(res => {
          teamRequestFiltered.value = res.data;
          isLoading.value = false;
        })
        .catch(err => {
          console.error(err);
        });
    };

    return {
      teamRequestFiltered,
      displaySkeleton,
    };
  },
});
