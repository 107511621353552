import { render, staticRenderFns } from "./SButtonCard.vue?vue&type=template&id=5fab6043&scoped=true"
import script from "./sButtonCard.ts?vue&type=script&lang=ts&external"
export * from "./sButtonCard.ts?vue&type=script&lang=ts&external"
import style0 from "./SButtonCard.vue?vue&type=style&index=0&id=5fab6043&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fab6043",
  null
  
)

export default component.exports