var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mr-6", attrs: { id: "team-detail" } },
    [
      _c(
        "v-row",
        { staticClass: "pa-0 ma-0" },
        [
          _c("v-col", { staticClass: "pa-0 ma-0 mr-4" }, [
            _c("div", { staticClass: "box px-3 py-2" }, [
              _c(
                "span",
                {
                  staticClass: "title-info mb-2",
                  domProps: {
                    textContent: _vm._s(_vm.$t("managerTeamDetail.daily-cost")),
                  },
                },
                [_vm._v("coste diario")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "money" }, [
                _vm._v(_vm._s(_vm.$n(_vm.hourlyRate * 8, "currency"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("v-col", { staticClass: "pa-0 ma-0" }, [
            _c("div", { staticClass: "box px-3 py-2" }, [
              _c(
                "span",
                {
                  staticClass: "title-info mb-2",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("managerTeamDetail.monthly-cost")
                    ),
                  },
                },
                [_vm._v("coste mensual")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "money" }, [
                _vm._v(_vm._s(_vm.$n(_vm.hourlyRate * 8 * 20, "currency"))),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "pa-0 ma-0 mt-4" },
        [
          _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "12" } }, [
            _c("div", { staticClass: "box pa-3" }, [
              _c(
                "span",
                {
                  staticClass: "title-info mb-2",
                  domProps: {
                    textContent: _vm._s(_vm.$t("managerTeamDetail.team-name")),
                  },
                },
                [_vm._v("nombre de equipo")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "info-description" }, [
                _vm._v(_vm._s(_vm.teamRequest.name)),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.teamRequest.client.company
        ? _c(
            "v-row",
            { staticClass: "pa-0 mx-0 mb-0 mt-4" },
            [
              _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "12" } }, [
                _c("div", { staticClass: "box pa-3" }, [
                  _c(
                    "span",
                    {
                      staticClass: "title-info mb-2",
                      domProps: {
                        textContent: _vm._s(_vm.$t("managerTeamDetail.client")),
                      },
                    },
                    [_vm._v("cliente")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "info-description" }, [
                    _c("span", { staticClass: "info-client" }, [
                      _vm._v(_vm._s(_vm.teamRequest.client.company.name)),
                    ]),
                    _vm._v(
                      "\n          " + _vm._s(_vm.teamRequest.client.name)
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "pa-0 ma-0 mt-4" },
        [
          _vm.teamRequest.endDate
            ? _c("v-col", { staticClass: "pa-0 ma-0 mr-4" }, [
                _c("div", { staticClass: "box pa-3" }, [
                  _c(
                    "span",
                    {
                      staticClass: "title-info mb-2",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("managerTeamDetail.collaboration-start")
                        ),
                      },
                    },
                    [_vm._v("Inicio colaboración")]
                  ),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "info-description",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$d(Date.parse(_vm.teamRequest.initDate, "decimal"))
                      ),
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.teamRequest.endDate
            ? _c("v-col", { staticClass: "pa-0 ma-0" }, [
                _c("div", { staticClass: "box pa-3" }, [
                  _c(
                    "span",
                    {
                      staticClass: "title-info mb-2",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("managerTeamDetail.end-collaboration")
                        ),
                      },
                    },
                    [_vm._v("Fin colaboración")]
                  ),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "info-description",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$d(Date.parse(_vm.teamRequest.endDate, "decimal"))
                      ),
                    },
                  }),
                ]),
              ])
            : _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "12" } }, [
                _c("div", { staticClass: "box pa-3" }, [
                  _c(
                    "span",
                    {
                      staticClass: "title-info mb-2",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("managerTeamDetail.collaboration-start")
                        ),
                      },
                    },
                    [_vm._v("Inicio colaboración")]
                  ),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "info-description",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$d(Date.parse(_vm.teamRequest.initDate, "decimal"))
                      ),
                    },
                  }),
                ]),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "pa-0 mx-0 mb-0 mt-4" },
        [
          _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "12" } }, [
            _c("div", { staticClass: "box pa-3" }, [
              _c(
                "span",
                {
                  staticClass: "title-info mb-2",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("managerTeamDetail.filters-used")
                    ),
                  },
                },
                [_vm._v("Filtros utilizados")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "info-description" }, [
                _vm._v(" --- "),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "pa-0 mx-0 mb-0 mt-4" },
        [
          _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "12" } }, [
            _c("div", { staticClass: "box pa-3" }, [
              _c(
                "span",
                {
                  staticClass: "title-info mb-2",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("managerTeamDetail.observations")
                    ),
                  },
                },
                [_vm._v("Observaciones")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "info-description" }, [
                _vm._v(_vm._s(_vm.teamRequest.job_description)),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "mt-6 p-0" },
        [
          _vm.teamRequest.status == _vm.RequestStatus.PENDING &&
          _vm.isAnyRateModified === false
            ? _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mr-6" },
                    [
                      _c("SButton", {
                        attrs: {
                          sText: _vm.$t("managerTeamDetail.Approve"),
                          btnStyle: "primary",
                          action: _vm.approveRequest,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("SButton", {
                        attrs: {
                          sText: _vm.$t("managerTeamDetail.Reject"),
                          btnStyle: "secondary",
                          action: _vm.cancelRequest,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.teamRequest.status == _vm.RequestStatus.PENDING &&
          _vm.isAnyRateModified === true
            ? _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("SButton", {
                        attrs: {
                          sText: _vm.$t("managerTeamDetail.sendProposal"),
                          btnStyle: "primary",
                          action: _vm.sendProposal,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.teamRequest.status == _vm.RequestStatus.VALIDATED
            ? _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("SButton", {
                        attrs: {
                          sText: _vm.$t("managerTeamDetail.sendNewProposal"),
                          btnStyle: "primary",
                          action: _vm.sendProposal,
                          disabled: _vm.isAnyRateModified === false,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          title: _vm.$t("managerTeamDetail.modal-title-accept"),
          isOpen: _vm.isTRApprovedModalOpen,
          closeModal: _vm.closeTRApprovedModal,
          customProps: _vm.customModalProps,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-body-1 text--text text--lighten-1" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("managerTeamDetail.modal-body-accept")) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("SButton", {
                  attrs: {
                    action: _vm.closeTRApprovedModal,
                    sText: _vm.$t("managerTeamDetail.modal-btn-accept"),
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          title: _vm.$t("managerTeamDetail.modal-title-cancel"),
          isOpen: _vm.isTRCancelledModalOpen,
          closeModal: _vm.closeTRCancelledModal,
          customProps: _vm.customModalProps,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-body-1 text--text text--lighten-1" },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("managerTeamDetail.modal-body-accept"))
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("SButton", {
                  attrs: {
                    action: _vm.closeTRCancelledModal,
                    sText: _vm.$t("managerTeamDetail.modal-btn-accept"),
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          title: _vm.$t("managerTeamDetail.modal-send-proposal"),
          isOpen: _vm.isSendProposalModalOpen,
          closeModal: _vm.closeSendProposalModal,
          customProps: _vm.customModalProps,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-body-1 text--text text--lighten-1" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("managerTeamDetail.modal-body-send-proposal")
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("SButton", {
                  attrs: {
                    action: _vm.closeSendProposalModal,
                    sText: _vm.$t("managerTeamDetail.modal-btn-accept"),
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }