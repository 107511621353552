import localStorageFacade from '@/shared/local-store-facade/LocalStoreFacade';
import { Module } from 'vuex';

interface ClientIdentity {
  id: number | undefined;
  name: string | undefined;
  surname: string | undefined;
}
export interface AccountStateStorable {
  logon: boolean;
  userIdentity: null | any;
  clientIdentity: ClientIdentity;
  authenticated: boolean;
  ribbonOnProfiles: string;
  activeProfiles: string;
}

export const defaultAccountState: AccountStateStorable = {
  logon: false,
  userIdentity: null,
  clientIdentity: { id: undefined, name: undefined, surname: undefined },
  authenticated: false,
  ribbonOnProfiles: '',
  activeProfiles: '',
};

export const accountStore: Module<AccountStateStorable, any> = {
  state: { ...defaultAccountState },
  getters: {
    logon: state => state.logon,
    account: state => state.userIdentity,
    clientAccount: state => state.clientIdentity,
    clientId: state => state.clientIdentity.id,
    client: state => state.clientIdentity,
    authenticated: state => state.authenticated,
    activeProfiles: state => state.activeProfiles,
    ribbonOnProfiles: state => state.ribbonOnProfiles,
  },
  mutations: {
    authenticate(state, identity) {
      state.userIdentity = identity;
      state.authenticated = true;
      state.logon = false;
      state.logon = true;
    },
    addClient(state, client) {
      state.clientIdentity.id = client.id;
      state.clientIdentity.name = client.name;
      state.clientIdentity.surname = client.surname;
    },
    logout(state) {
      state.userIdentity = null;
      state.authenticated = false;
      state.logon = false;
      state.clientIdentity = { id: undefined, name: undefined, surname: undefined };
    },
    setActiveProfiles(state, profile) {
      state.activeProfiles = profile;
    },
    setRibbonOnProfiles(state, ribbon) {
      state.ribbonOnProfiles = ribbon;
    },
  },
};
