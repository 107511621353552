<template>
  <v-list-item-icon v-if="item.isTest" class="mx-0 my-auto align-center">
    <div class="test-badge-wrapper">
      <span class="test-badge text-body-3-medium">TEST</span>
    </div>
  </v-list-item-icon>
</template>

<script lang="ts">
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
/* Style for test badge */
.test-badge-wrapper {
  background: var(--v-secondary-base);
  border-radius: 50px;
  height: 21px;
  max-width: 45px;
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.test-badge {
  color: white;
}
</style>
