import axios from 'axios';
import Component from 'vue-class-component';
export default class RegisterService {
  public processRegistration(account: any, client?: any): Promise<any> {
    const registrationData = {
      account: account,
      client: client,
    };
    return axios.post('api/register', registrationData);
  }

  public openRegister(instance: Vue): void {
    instance.$emit('bv::show::modal', 'register-page');
  }

  public bytesToBase64(bytes) {
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString);
  }
}
