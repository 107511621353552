var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "range-slider-wraper pt-10 pb-8 px-6" },
    [
      _c("v-range-slider", {
        staticClass: "custom-range-slider",
        attrs: {
          color: "primary",
          "track-color": "border",
          step: _vm.step,
          min: _vm.min,
          max: _vm.max,
          "thumb-size": "28",
          "thumb-label": "always",
        },
        on: { change: _vm.hadleChange },
        scopedSlots: _vm._u([
          {
            key: "thumb-label",
            fn: function ({ value }) {
              return [
                _c("span", { staticClass: "text-body-3" }, [
                  _vm._v(_vm._s(value) + "€"),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }