var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12" } },
    [
      _c(
        "v-row",
        _vm._l(8, function (skeletonCard) {
          return _c(
            "v-col",
            {
              key: skeletonCard,
              attrs: { xs: "12", sm: "12", md: "12", lg: "6", xl: "6" },
            },
            [
              _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { card: "", height: "100%" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c(
                            "v-card",
                            { attrs: { id: "stemdoer-card-skeleton" } },
                            [
                              _c(
                                "v-container",
                                {
                                  staticClass: "p-0 m-0 h-100 w-100",
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "h-100 w-100",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: { cols: "auto" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "d-flex justify-center flex-grow-1",
                                              staticStyle: {
                                                width: "157px",
                                                height: "100%",
                                              },
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _c("v-skeleton-loader", {
                                                staticClass:
                                                  "mx-auto full-height-image",
                                                staticStyle: {
                                                  width: "100%",
                                                  "border-radius":
                                                    "4px 0 0 4px",
                                                },
                                                attrs: { type: "image" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex flex-column justify-space-between p-3 h-100 w-100",
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("v-skeleton-loader", {
                                                staticClass: "mx-auto p-1",
                                                staticStyle: { width: "100%" },
                                                attrs: { type: "text@2" },
                                              }),
                                              _vm._v(" "),
                                              _c("v-skeleton-loader", {
                                                staticClass:
                                                  "mx-auto p-1 d-flex justify-start flex-wrap",
                                                staticStyle: { width: "100%" },
                                                attrs: { type: "chip@4" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "mt-auto" },
                                            [
                                              _c("v-skeleton-loader", {
                                                staticClass:
                                                  "d-flex justify-end pr-2 pb-1 w-100 mx-2",
                                                attrs: { type: "avatar@2" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }