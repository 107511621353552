var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "m-0 px-8 pt-6",
      attrs: { id: "candidates-wrapper", fluid: "" },
    },
    [
      _c(
        "v-col",
        {
          staticClass: "pa-0 ma-0 d-flex align-center",
          attrs: { col: "2", fluid: "" },
        },
        [
          _c("h3", {
            staticClass: "text-h3 title-page",
            domProps: { textContent: _vm._s(_vm.$t("candidate.title")) },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-3" },
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12" } },
            [
              _c(
                "v-container",
                {
                  staticClass:
                    "d-flex candidates-tip justify-space-between align-items-center mx-0 mb-0 pl-0 pt-0",
                  attrs: { fluid: "" },
                },
                [
                  _c("span", {
                    staticClass: "text-subtitle-3",
                    domProps: { textContent: _vm._s(_vm.$t("candidate.tip")) },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "p-3 assigned-candidates d-flex align-items-center justify-space-between",
                    },
                    [
                      _c("span", {
                        staticClass: "text-body-1-medium assigned-info",
                        domProps: {
                          innerHTML: _vm._s(
                            "0&nbsp;" + _vm.$t("candidate.not-assigned")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "settings ml-3 mr-2",
                        attrs: {
                          src: "/content/svgs/settings-outline.svg",
                          alt: "settings",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("v-progress-circular", {
            attrs: { width: 2, color: "grey", indeterminate: "" },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? [
            _c("CandidateFilter"),
            _vm._v(" "),
            _c("CandidateList", { attrs: { candidates: _vm.candidates } }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }