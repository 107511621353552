var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "read-more" } }, [
    !_vm.isReadingMore
      ? _c("span", {
          staticClass: "description",
          domProps: {
            innerHTML: _vm._s(_vm.text.slice(0, _vm.maxLength) + "..."),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    !_vm.isReadingMore
      ? _c("a", { on: { click: _vm.readMore } }, [
          _vm._v("\n    " + _vm._s(_vm.$t("sReadMore.btn-readMore")) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isReadingMore
      ? _c("span", {
          staticClass: "description",
          domProps: { innerHTML: _vm._s(_vm.text) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }