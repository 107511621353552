import { defineComponent, onMounted, ref, computed, watch } from 'vue';
import SButtonBack from '@/components/stemdo-components/s-button-back/SButtonBack.vue';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import TeamDetail from './team-detail/TeamDetail.vue';
import { useStore } from '@/plugins/vuex';
import { useRouter } from '@/plugins/router';

export default defineComponent({
  components: {
    SButtonBack,
    TeamDetail,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const stemdoers = ref<IStemdoer[]>([]);
    const rate = ref(0);
    const hourlyCost = ref(0);

    const clientSelected = computed(() => store.getters['cartStore/clientSelected']);
    const cartItems = computed(() => store.getters['cartStore/cartItems']);

    onMounted(() => {
      if (stemdoers.value.length > 0) {
        getRate();
      }
    });

    const getRate = () => {
      let ratesSource: any[] = [];
      hourlyCost.value = 0;

      if (clientSelected.value) {
        ratesSource = clientSelected.value.clientRate?.rates || [];
      }

      if (Array.isArray(ratesSource)) {
        stemdoers.value.forEach((stemdoer: IStemdoer) => {
          stemdoer.rate = 36;
          ratesSource.forEach(item => {
            const seniority = item.seniority.toLowerCase().replace(/[-_]/g, '');
            const stemdoerSeniority = stemdoer.seniority.toLowerCase().replace(/[-_]/g, '');
            if (seniority === stemdoerSeniority) {
              rate.value = item.value;
              stemdoer.rate = rate.value;
            }
          });
          hourlyCost.value += stemdoer.rate;
        });
      }
      return hourlyCost.value;
    };

    const updateRate = (stemdoerId: string, newRate: number) => {
      const stemdoer = stemdoers.value.find(s => s.id === stemdoerId);
      if (stemdoer) {
        stemdoer.rate = newRate;
        hourlyCost.value = stemdoers.value.reduce((total, s) => total + s.rate, 0);
      }
    };

    const redirectToExplore = () => {
      router.push({ name: 'exploreManager' });
    };

    const deleteStemdoer = id => {
      try {
        if (id) {
          store.commit('cartStore/removeStemdoer', id);

          const cartTotalItems = store.getters['cartStore/cartTotalItems'];
          if (cartTotalItems < 1) {
            redirectToExplore();
          }
        } else {
          throw new Error(`Stemdoer with id: ${id} not found`);
        }
      } catch (error) {
        console.error(`Error deleting Stemdoer with id: ${id}`, error);
      }
    };

    watch(
      cartItems,
      newCartItems => {
        stemdoers.value = newCartItems;
        getRate();
      },
      { immediate: true }
    );

    return { stemdoers, cartItems, clientSelected, hourlyCost, updateRate, deleteStemdoer };
  },
});
