import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import CandidateList from '@/sections/candidates/candidate-list/CandidateList.vue';
import CandidateFilter from '@/sections/candidates/candidate-filter/CandidateFilter.vue';
import StemdoerService from '@/entities/stemdoer/stemdoer.service';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import { useStore } from '@/plugins/vuex';

export default defineComponent({
  components: {
    CandidateList,
    CandidateFilter,
  },
  setup() {
    const store = useStore();
    const stemdoerService: StemdoerService = inject('stemdoerService');
    const candidates = ref<IStemdoer[]>([]);
    const isLoading = ref(true);

    onMounted(() => {
      retrieveCandidates();
    });

    const retrieveCandidates = async () => {
      const loggedClientId = store.getters['client'].id;
      const currentDate = new Date();
      candidates.value = await stemdoerService.retrieveByClientTeams(loggedClientId, currentDate);
      isLoading.value = false;
    };

    return {
      candidates,
      isLoading,
      retrieveCandidates,
    };
  },
});
