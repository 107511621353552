import { IStemdoer } from '@/shared/model/stemdoer.model';

export class Cart {
  private items: IStemdoer[];

  constructor() {
    this.items = [];
  }

  public addToCart(stemdoer: IStemdoer): void {
    this.items.push(stemdoer);
  }

  public removeFromCart(stemdoerId: string): void {
    this.items = this.items.filter(item => item.id !== stemdoerId);
  }

  public getItems(): IStemdoer[] {
    return this.items;
  }

  public getTotalItems(): number {
    return this.items.length;
  }
}
