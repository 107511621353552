import { IClient } from '@/shared/model/client.model';
import { IVertical } from '@/shared/model/vertical.model';
import { ICertification } from '@/shared/model/certification.model';

import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { ITeamActivity } from './team-activity.model';
import { IStemdoerRate } from './stemdoer-rate.model';
import { IStemdoer } from './stemdoer.model';

export interface ITeamRequest {
  id?: string;
  name?: string | null;
  createdAt?: Date;
  initDate?: Date;
  endDate?: Date;
  english?: boolean | null;
  paramsUrl?: string | null;
  techPreferences?: string | null;
  jobDescription?: string | null;
  hourlyRate?: number | null;
  status?: RequestStatus | null;
  client?: IClient;
  clientId?: string;
  vertical?: IVertical | null;
  certifications?: ICertification[] | null;
  stemdoers?: IStemdoer[] | null;
  stemdoerRates?: IStemdoerRate[] | null;
  teamActivities?: ITeamActivity[] | null;
}

export class TeamRequest implements ITeamRequest {
  constructor(
    public id?: string,
    public name?: string | null,
    public createdAt?: Date,
    public initDate?: Date,
    public endDate?: Date,
    public english?: boolean | null,
    public paramsUrl?: string | null,
    public techPreferences?: string | null,
    public jobDescription?: string | null,
    public hourlyRate?: number | null,
    public status?: RequestStatus | null,
    public client?: IClient,
    public clientId?: string,
    public vertical?: IVertical | null,
    public certifications?: ICertification[] | null,
    public stemdoers?: IStemdoer[] | null,
    public stemdoerRates?: IStemdoerRate[] | null,
    public teamActivities?: ITeamActivity[] | null
  ) {
    this.english = this.english ?? false;
  }
}
