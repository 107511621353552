import { Authority } from '@/shared/security/authority';

const Register = () => import('@/account/register/Register.vue');
const Activate = () => import('@/account/activate/activate.vue');
const ResetPasswordInit = () => import('@/account/reset-password/init/ResetPasswordInit.vue');
const ResetPasswordFinish = () => import('@/account/reset-password/finish/ResetPasswordFinish.vue');
const ChangePassword = () => import('@/account/change-password/change-password.vue');
const Settings = () => import('@/account/settings/settings.vue');
const loginForm = () => import('@/account/login/Login.vue');
const register = () => import('@/account/register/Register.vue');

//

//

export default [
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/account/activate',
    name: 'Activate',
    component: Activate,
  },
  {
    path: '/account/reset/request',
    name: 'ResetPasswordInit',
    component: ResetPasswordInit,
  },
  {
    path: '/account/reset/finish',
    name: 'ResetPasswordFinish',
    component: ResetPasswordFinish,
  },
  {
    path: '/account/password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { authorities: [Authority.RRHH, Authority.ADMIN, Authority.CLIENT] },
  },
  {
    path: '/account/settings',
    name: 'Settings',
    component: Settings,
    meta: { authorities: [Authority.RRHH, Authority.ADMIN, Authority.CLIENT] },
  },

  {
    path: '/login',
    name: 'login',
    component: loginForm,
    meta: { authorities: [] },
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    meta: { authorities: [] },
  },
];
