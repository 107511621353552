/* eslint-disable prettier/prettier */
import { PropType, defineComponent, ref } from 'vue';

type StyleProps = {
  bodyPaddingY: number;
  fullWidthFooter?: boolean;
};

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '500px',
    },
    closeModal: {
      type: Function as PropType<() => void>,
      required: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    customProps: {
      type: Object as () => StyleProps,
    },
  },
  setup(props) {
    const defaultProps: StyleProps = {
      bodyPaddingY: 2,
      fullWidthFooter: false,
    };
    const newProps: StyleProps = {
      ...defaultProps,
      ...props.customProps,
    };

    const close = () => {
      props.closeModal();
    };

    const clickOutside = () => {
      if (!props.persistent) {
        close();
      }
      focusVDialogComponent();
    };

    const keyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    };

    const focusVDialogComponent = () => {
      const dialogComponent = document.getElementsByClassName('v-dialog-component')[0] as HTMLElement;
      dialogComponent?.focus();
    };

    return { close, clickOutside, keyDown, newProps };
  },
});
