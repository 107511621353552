import { render, staticRenderFns } from "./BtnMyTeam.vue?vue&type=template&id=fb514d10&scoped=true"
import script from "./btnMyTeam.ts?vue&type=script&lang=ts&external"
export * from "./btnMyTeam.ts?vue&type=script&lang=ts&external"
import style0 from "./BtnMyTeam.vue?vue&type=style&index=0&id=fb514d10&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb514d10",
  null
  
)

export default component.exports