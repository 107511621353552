var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "mx-0 mb-8 pl-0", attrs: { id: "request-list" } },
    [
      _c("v-data-table", {
        staticClass: "remove-box-shadow custom-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.teamRequestDisplayed,
          "hide-default-footer": "",
          "disable-pagination": "",
          "disable-sort": "",
        },
        scopedSlots: _vm._u([
          {
            key: "body.prepend",
            fn: function () {
              return [_c("div", { staticStyle: { height: "10px" } })]
            },
            proxy: true,
          },
          {
            key: "item.stemdoers",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  { staticClass: "chip-container" },
                  [
                    _vm._l(item.stemdoerRates, function (stemdoerRate, index) {
                      return [
                        index < 3
                          ? _c(
                              "v-chip",
                              {
                                key: "chip-rate-" + index,
                                staticClass: "overlapping-chip no-padding",
                                attrs: { color: "transparent" },
                              },
                              [
                                _c("StemdoerAvatar", {
                                  staticClass: "avatar",
                                  attrs: {
                                    stemdoId: stemdoerRate.stemdoer.stemdoId,
                                  },
                                }),
                              ],
                              1
                            )
                          : index === 3
                          ? _c(
                              "v-chip",
                              {
                                key: "chip-rate-" + index,
                                staticClass: "overlapping-chip no-padding",
                                attrs: { color: "transparent" },
                              },
                              [
                                _vm._v(
                                  "\n            +" +
                                    _vm._s(item.stemdoerRates.length - 3) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "item.client",
            fn: function ({ item }) {
              return [
                _c("div", { staticClass: "client-info" }, [
                  _c("span", { staticClass: "name-cell" }, [
                    _vm._v(
                      _vm._s(item.client.name) +
                        " " +
                        _vm._s(item.client.surname)
                    ),
                  ]),
                  _vm._v(" "),
                  item.client && item.client.company && item.client.company.name
                    ? _c(
                        "span",
                        {
                          staticClass: "text-body-1",
                          staticStyle: { color: "var(--v-text-lighten1)" },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.client.company.name) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "item.createdAt",
            fn: function ({ item }) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$d(Date.parse(item.createdAt), "twoDigit"))
                  ),
                ]),
              ]
            },
          },
          {
            key: "item.initDate",
            fn: function ({ item }) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.$d(Date.parse(item.initDate), "twoDigit"))),
                ]),
              ]
            },
          },
          {
            key: "item.hourlyRate",
            fn: function ({ item }) {
              return [
                _c("span", [
                  _vm._v(
                    "≈" + _vm._s(_vm.$n(item.hourlyRate * 8, "decimal")) + "€/d"
                  ),
                ]),
              ]
            },
          },
          {
            key: "item.status",
            fn: function ({ item }) {
              return [
                _c("div", { staticClass: "status-container" }, [
                  _c("span", {
                    staticClass: "text-body-3 p-1 pl-3 pr-3",
                    domProps: {
                      textContent: _vm._s(
                        _vm.getStatusDetails(item.status).translation
                      ),
                    },
                  }),
                ]),
              ]
            },
          },
          {
            key: "item.action",
            fn: function ({ item }) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "teamRequestByIdManager",
                        params: { id: item.id },
                      },
                    },
                  },
                  [
                    _c(
                      "v-btn",
                      { staticClass: "v-icon-detail", attrs: { icon: "" } },
                      [
                        _c("img", {
                          attrs: {
                            src: "content/svgs/eye-outline.svg",
                            alt: "detail",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }