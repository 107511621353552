var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "mx-0 pl-0 pt-0 pb-0",
      attrs: { id: "candidate-filter", fluid: "" },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex align-center filter-container" },
        [
          _c(
            "v-row",
            { staticClass: "d-flex align-center p-2" },
            [
              _c("v-col", {
                staticClass: "text-left p-0 pl-4",
                attrs: { cols: "12" },
              }),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "p-2", attrs: { cols: "2" } },
                [
                  _c("v-autocomplete", {
                    staticClass: "custom-v-autocomplete",
                    attrs: {
                      placeholder: _vm.$t("candidate.filter.team"),
                      labelDisplaySelection: true,
                      inputHeight: 45,
                      dense: "",
                      flat: "",
                      solo: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "p-2", attrs: { cols: "2" } },
                [
                  _c("v-autocomplete", {
                    staticClass: "custom-v-autocomplete",
                    attrs: {
                      placeholder: _vm.$t("candidate.filter.status"),
                      labelDisplaySelection: true,
                      inputHeight: 45,
                      dense: "",
                      flat: "",
                      solo: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "p-2 pl-3 custom-v-search",
                  attrs: { cols: "6" },
                },
                [
                  _c("v-text-field", {
                    staticClass: "custom-text-field",
                    attrs: {
                      density: "compact",
                      label: _vm.$t("candidate.filter.search"),
                      "prepend-inner-icon": "mdi-magnify",
                      variant: "solo-filled",
                      flat: "",
                      solo: "",
                      dense: "",
                      "hide-details": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }