import axios from 'axios';
import localStorageFacade, { StorageKey } from './local-store-facade/LocalStoreFacade';

const baseApiUrl = 'api/import';

export default class ResourceService {
  public findImg(resource: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/foto?resource=' + resource, {
          headers: {
            Authorization: 'Bearer ' + localStorageFacade.get(StorageKey.AUTH_TOKEN),
          },
          responseType: 'blob',
        })
        .then(res => {
          if (res.data.type.includes('image')) {
            resolve(URL.createObjectURL(res.data));
          } else {
            resolve(res.headers['x-initials']);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findLogo(resource: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/logo?resource=' + resource, {
          headers: {
            Authorization: 'Bearer ' + localStorageFacade.get(StorageKey.AUTH_TOKEN),
          },
          responseType: 'blob',
        })
        .then(res => {
          resolve(URL.createObjectURL(res.data));
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getFootPrint(stemdoerId: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/footprint?stemdoerID=' + stemdoerId, {
          headers: {
            Authorization: 'Bearer ' + localStorageFacade.get(StorageKey.AUTH_TOKEN),
          },
          responseType: 'json',
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public generatePDF(stemdoerId: string, dataNecessary: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/files?stemdoerID=' + stemdoerId, {
          responseType: 'blob',
          params: {
            dataNecessary: dataNecessary,
          },
        })
        .then(res => {
          const date = new Date().toISOString().split('T')[0];
          const fileUrl = URL.createObjectURL(res.data);
          const downloadLink = document.createElement('a');
          const moreThanOne = stemdoerId.split(',').length > 1 ? 'TeamRequest' : 'Stemdoer';
          downloadLink.href = fileUrl;
          downloadLink.download = moreThanOne + ' - ' + date + '.pdf';

          document.body.appendChild(downloadLink);
          downloadLink.click();
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  }

  public sendEmailWithTRDetails(stemdoerIds: string[], dataNecessary: string, emailToSend: string): Promise<void> {
    const stemdoerIdsStr = stemdoerIds.map(String).join(',');
    return new Promise<void>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/pdf?email=${emailToSend}`, {
          params: {
            listIdMongo: stemdoerIdsStr,
            dataNecessary: dataNecessary,
          },
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  }
}
