var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.display
    ? _c("div", [
        _c("img", {
          attrs: {
            width: "13px",
            src: "/content/svgs/alert-circle.svg",
            alt: "alert-icon",
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "text-body-3 text--text text--lighten-1" }, [
          _vm._v(_vm._s(_vm.$t("explore.no-result-info"))),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }