import { IClientRate } from '@/shared/model/client-rate.model';

export interface IRate {
  seniority?: string;
  value?: number;
  clientRate?: IClientRate | null;
}

export class Rate implements IRate {
  constructor(public seniority?: string, public value?: number, public clientRate?: IClientRate | null) {}

  public toDTO(): IRate {
    return {
      seniority: this.seniority,
      value: this.value,
      clientRate: this.clientRate,
    };
  }

  public static toRate(json: any): Rate {
    return new Rate(json.seniority, json.value, json.clientRate);
  }
}
