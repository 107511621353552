var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "mx-0 mb-8 pl-0", attrs: { id: "request-list-skeleton" } },
    [
      _c(
        "div",
        {
          staticClass: "mb-6 d-flex flex-wrap",
          attrs: { id: "s-button-card" },
        },
        _vm._l(5, function (buttonIndex) {
          return _c(
            "v-btn",
            {
              key: `btn-${buttonIndex}`,
              class: `btn-main remove-box-shadow d-flex justify-content-start ${
                buttonIndex === 1 ? "ml-0 m-2" : "m-2"
              }`,
            },
            [
              _c("v-progress-circular", {
                staticClass: "progress-circular-custom",
                attrs: { indeterminate: "", size: 38, width: 5 },
              }),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "mt-6 remove-box-shadow custom-table",
        attrs: {
          headers: _vm.headers,
          "hide-default-footer": "",
          "disable-pagination": "",
          "disable-sort": "",
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticStyle: { height: "10px" } }),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(5, function (item) {
                      return [
                        _c(
                          "tr",
                          { key: `row-${item}` },
                          _vm._l(1, function (index) {
                            return _c(
                              "td",
                              { key: `row-${item}-item-${index}` },
                              [
                                _c("v-skeleton-loader", {
                                  staticClass: "w-100",
                                  attrs: { type: "text" },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }