var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-group",
    {
      key: _vm.item.title,
      class: [
        "ml-5 pl-0 pr-2 mb-1",
        !_vm.isParentActiveLink(_vm.item.items, _vm.$route.path) &&
        _vm.isGroupParentHovered
          ? "hover-item"
          : "",
        _vm.isParentActiveLink(_vm.item.items, _vm.$route.path)
          ? "active-parent-subitems"
          : "",
      ],
      attrs: { disabled: _vm.item.disable, value: _vm.isParentExpanded },
      on: {
        mouseenter: function ($event) {
          return _vm.onGroupParentHover(true)
        },
        mouseleave: function ($event) {
          return _vm.onGroupParentHover(false)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _c("ItemContent", {
                attrs: {
                  item: _vm.item,
                  isActive: _vm.isParentActiveLink(
                    _vm.item.items,
                    _vm.$route.path
                  ),
                  hover: _vm.isGroupParentHovered,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._l(_vm.item.items, function (subItem, index) {
        return _c("SubItemContent", {
          key: subItem.title,
          attrs: {
            item: subItem,
            isLast: index === _vm.item.items.length - 1,
            isActiveLink: _vm.isActiveLink,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }