import { render, staticRenderFns } from "./SButton.vue?vue&type=template&id=5b0b110a&scoped=true"
import script from "./sButton.ts?vue&type=script&lang=ts&external"
export * from "./sButton.ts?vue&type=script&lang=ts&external"
import style0 from "./SButton.vue?vue&type=style&index=0&id=5b0b110a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0b110a",
  null
  
)

export default component.exports