import { jwtDecode } from 'jwt-decode';
import { Authority, getAuthorityEnum } from './authority';
import localStorageFacade, { StorageKey } from '../local-store-facade/LocalStoreFacade';

export type AuthTokenPayload = {
  sub: string;
  auth: string;
  exp: number;
};
export default class AuthToken {
  public static decode(): AuthTokenPayload {
    const authToken = localStorageFacade.get(StorageKey.AUTH_TOKEN);
    if (!authToken) throw new Error('No auth token found');
    return jwtDecode<AuthTokenPayload>(authToken);
  }

  public static getAuthorities = (): Authority[] => {
    const payload = this.decode();
    const auths: Authority[] = payload.auth.split(',').map(s => getAuthorityEnum(s.trim()));
    return auths;
  };

  public static hasAnyAuthority = (authorities: Authority[]): boolean => {
    return this.getAuthorities().some(auth => authorities.includes(auth));
  };
}
