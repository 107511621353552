import { defineComponent, computed } from 'vue';

export default defineComponent({
  components: {},

  setup() {
    const headers = computed(() => [{ text: '', value: 'skeleton' }]);

    return {
      headers,
    };
  },
});
