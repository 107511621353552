import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ITeamRequest } from '@/shared/model/team-request.model';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';

interface GetAllTeamRequestsByClientIdBody {
  requestStatus: RequestStatus[];
}

const baseApiUrl = 'api/team-requests';

export default class TeamRequestService {
  public find(id: string): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findAll(id: string): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/end/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAllTeamRequestsByClient(paginationQuery?: any, clientEmail?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/client?${buildPaginationQueryOpts(paginationQuery)}`, {
          params: {
            clientEmail: clientEmail,
          },
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAllTeamRequestsByClientId(clientId: string, body?: GetAllTeamRequestsByClientIdBody, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(baseApiUrl + `/client/${clientId}?${buildPaginationQueryOpts(paginationQuery)}`, body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAllTeamsByClient(paginationQuery?: any, clientEmail?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/team?${buildPaginationQueryOpts(paginationQuery)}`, {
          params: {
            clientEmail: clientEmail,
          },
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAllRequestsByManager(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/manager?${buildPaginationQueryOpts(paginationQuery)}`, {})
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAllTeamByStatus(status?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/status?`, {
          params: {
            status: status,
          },
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveAllByStemdoer(paginationQuery?: any, stemdoerId?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/stemdoer?stemdoerId=${stemdoerId}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveAllByStemdoers(paginationQuery?: any, stemdoersIds?: string[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/stemdoers?stemdoersIds=${stemdoersIds}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ITeamRequest): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ITeamRequest): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ITeamRequest): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public blockAndPartialUpdate(entity: ITeamRequest): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/block`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public validateAndPartialUpdate(entity: ITeamRequest): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/validate`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public reduceOrTerminateTeamRequest(teamRequestId: string, stemdoersId: string[], endDate: string) {
    const newEndDate = endDate;
    const body = { stemdoersId: stemdoersId, newEndDate: newEndDate };
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/stemdoers/${teamRequestId}`, { data: body })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public cancelOperationTeamRequest(teamRequestId: string, stemdoerId: string) {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/denyApplicationStemdoersEndDateTeam/${teamRequestId}/${stemdoerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public calculateTempTotal(clientId: string, stemdoerSeniorities: string[]): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/temp-total`, {
          clientId: clientId,
          stemdoerSeniorities: stemdoerSeniorities,
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public cancelTeamRequest(teamRequestId: string, status: string): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/${teamRequestId}/cancel/`, { status: status.toUpperCase() })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public changeStatus(teamRequestId: string, status: string): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${teamRequestId}/status/${status}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
