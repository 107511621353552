import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IClient } from '@/shared/model/client.model';

const baseApiUrl = 'api/clients';

export default class ClientService {
  public find(id: string): Promise<IClient> {
    return new Promise<IClient>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveWithRates(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/rates' + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findByEmail(email: string): Promise<IClient> {
    return new Promise<IClient>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/client/email?email=${email}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IClient): Promise<IClient> {
    return new Promise<IClient>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          if (res && res.data) {
            resolve(res.data);
          } else {
            reject(new Error('Respuesta vacía o sin datos'));
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IClient): Promise<IClient> {
    return new Promise<IClient>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IClient): Promise<IClient> {
    return new Promise<IClient>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
