import { Authority } from '@/shared/security/authority';
import { component } from 'vue/types/umd';
/* tslint:disable */
// prettier-ignore
const Components = () => import('@/components/components.vue');

// prettier-ignore
const Teams = () => import('@/components/teams/teams.vue');
const verticalStemdoers = () => import('@/components/vertical-stemdoers/vertical-stemdoers.vue');
const stemdoerHome = () => import('@/components/stemdoerHome/stemdoerHome.vue');
const teamRequest = () => import('@/components/teamRequest/teamRequest.vue');
const clientShow = () => import('@/components/clientShow/clientShow.component.vue');
const projects = () => import('@/components/projects/project.vue');
const activeStemdoers = () => import('@/components/active-stemdoers/active-stemdoers.vue');
const notification = () => import('@/components/notification/notification.component.vue');
const cohortRequestDetail = () => import('@/components/clientCohortRequest/cohortRequestDetail.component.vue');
const cohortRequestShow = () => import('@/components/clientCohortRequest/cohortRequest-show.component.vue');
const cohortRequest = () => import('@/components/cohortRequest/cohortRequest.component.vue');
const rrhhHome = () => import('@/components/rrhh/rrhh.component.vue');
const clientDashboard = () => import('@/components/clientDashboard/clientDashboard.component.vue');
const cookieInfo = () => import('@/components/CookieInfo/cookieInfo.component.vue');
const gestorDashboard = () => import('@/components/gestorDashboard/gestorDashboard.component.vue');
const clientTeam = () => import('@/components/clientTeam/clientTeam.component.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Components,
  children: [
    {
      path: '/teamRequestDetails',
      name: 'clientOrder',
      component: clientShow,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'stemdoerHome',
      name: 'stemdoerHome',
      component: stemdoerHome,
      props: true,
    },
    {
      path: 'teamRequest',
      name: 'teamRequest',
      component: teamRequest,
      props: true,
      meta: { authorities: [] },
    },
    {
      path: 'team',
      name: 'clientTeam',
      component: clientTeam,
      props: true,
      meta: { authorities: [] },
    },
    {
      path: 'cohortRequestDetail',
      name: 'cohortRequestDetail',
      component: cohortRequestDetail,
      props: true,
      meta: { authorities: [] },
    },
    {
      path: 'cohortRequestShow',
      name: 'cohortRequestShow',
      component: cohortRequestShow,
      props: true,
      meta: { authorities: [] },
    },
    {
      path: '/projects',
      name: 'Projects',
      component: projects,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] },
    },
    {
      path: '/active-stemdoers',
      name: 'ActiveStemdoers',
      component: activeStemdoers,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] },
    },
    {
      path: '/notificationView',
      name: 'NotificationV',
      component: notification,
      meta: { authorities: [Authority.ADMIN, Authority.RRHH, Authority.GESTOR] },
    },
    {
      path: 'cohortRequest',
      name: 'cohortRequest',
      component: cohortRequest,
      props: true,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] },
    },
    {
      path: '/rrhhHome',
      name: 'rrhhHome',
      component: rrhhHome,
      props: true,
      meta: { authorities: [Authority.RRHH] },
    },
    {
      path: '/clientDashboard',
      name: 'clientDashboard',
      component: clientDashboard,
      props: true,
      meta: { authorities: [] },
    },
    {
      path: '/cookieInfo',
      name: 'cookieInfo',
      component: cookieInfo,
      meta: { authorities: [] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
