var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.isClient && !_setup.isBoth
    ? _c(_setup.Requests)
    : _setup.isGestor && !_setup.isBoth
    ? _c(_setup.RequestsGestor)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }