import { IAtocompleteMultipleRatingState } from '@/components/stemdo-components/s-autocomplete-multiple-rating/sAutocompleteMultipleRating';
import { FilterFields, UIFiltersDef, UIFilterTypes, IAtocompleteMultipleRatingOperator, IAtocompleteMultipleRatingValue } from './Filters';
import { IAtocompleteMultipleState } from '@/components/stemdo-components/s-autocomplete-multiple/sAutocompleteMultiple';
import { IRangeSliderStepsRateState } from '@/components/stemdo-components/s-range-slider-steps-rate/sRangeSliderStepsRate';
import { useI18N } from '@/plugins/i18n';
import { IAtocompleteState } from '@/components/stemdo-components/s-autocomplete/sAutocomplete';
import { IStandardFilter } from '@/shared/model/StandardFilter';

export enum Operators {
  Equal = 'eq',
  LowerThan = 'lt',
  LowerThanOrEqual = 'lte',
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'gte',
  Contains = 'like',
  Between = 'between',
}

export interface IProps {
  operator: any;
  value: any;
}

export interface ITextValue {
  id: string;
  desc: string;
}

export interface INumberValue {
  id: number;
  desc: string;
}

export interface IRangeValue extends Array<number> {
  length: 2;
  0: number;
  1: number;
}

export interface IAtocompleteProps extends IProps {
  operator: Operators;
  value: ITextValue;
}

export interface IAtocompleteMultipleProps extends IProps {
  operator: Operators;
  value: ITextValue;
}

export interface IAtocompleteMultipleRatingProps extends IProps {
  operator: IAtocompleteMultipleRatingOperator;
  value: IAtocompleteMultipleRatingValue;
}

export interface IRangeSilderStesRateProps extends IProps {
  operator: Operators;
  value: IRangeValue;
}

export interface ICheckboxProps extends IProps {
  operator: Operators;
  value: boolean;
}

export interface ISwitchReverseProps extends IProps {
  operator: Operators;
  value: boolean;
}

export default class Filter {
  public field: FilterFields;
  public props:
    | IAtocompleteProps
    | IAtocompleteMultipleProps
    | IAtocompleteMultipleRatingProps
    | IRangeSilderStesRateProps
    | ICheckboxProps
    | ISwitchReverseProps;

  constructor(
    field: FilterFields,
    props: IAtocompleteProps | IAtocompleteMultipleProps | IAtocompleteMultipleRatingProps | IRangeSilderStesRateProps | ICheckboxProps
  ) {
    this.field = field;

    if (UIFiltersDef[field] === UIFilterTypes.Autocomplete) {
      if (!props.operator) {
        throw new Error('Operator is required for this type of filter');
      }
      if (!props.value) {
        throw new Error('Value is required for this type of filter');
      }
      this.props = props as IAtocompleteProps;
    } else if (UIFiltersDef[field] === UIFilterTypes.AutocompleteMutiple) {
      if (!props.operator) {
        throw new Error('Operator is required for this type of filter');
      }
      if (!props.value) {
        throw new Error('Value is required for this type of filter');
      }
      this.props = props as IAtocompleteMultipleProps;
    } else if (UIFiltersDef[field] === UIFilterTypes.AutocompleteMutipleRating) {
      if (!props.operator && (props.operator as IAtocompleteMultipleRatingOperator).length !== 2) {
        throw new Error('Operator is required for this type of filter');
      }
      if (!props.value && (props.value as IAtocompleteMultipleRatingValue).length !== 2) {
        throw new Error('Value is required for this type of filter');
      }
      this.props = props as IAtocompleteMultipleRatingProps;
    } else if (UIFiltersDef[field] === UIFilterTypes.RangeSilderStesRate) {
      if (!props.operator) {
        throw new Error('Operator is required for this type of filter');
      }
      if (!props.value && (props.value as IRangeValue).length !== 2) {
        throw new Error('Value is required for this type of filter');
      }
      this.props = props as IRangeSilderStesRateProps;
    } else if (UIFiltersDef[field] === UIFilterTypes.Checkbox) {
      if (!props.operator) {
        throw new Error('Operator is required for this type of filter');
      }
      if (props.value === undefined || props.value === null) {
        throw new Error('Value is required for this type of filter');
      }
      this.props = props as ICheckboxProps;
    } else if (UIFiltersDef[field] === UIFilterTypes.SwitchReverse) {
      if (!props.operator) {
        throw new Error('Operator is required for this type of filter');
      }
      if (props.value === undefined || props.value === null) {
        throw new Error('Value is required for this type of filter');
      }
      this.props = props as ISwitchReverseProps;
    }
  }

  static clone = (sourceFilter: Filter): Filter => {
    const sourceField = sourceFilter.field;
    let props;

    if (UIFiltersDef[sourceField] === UIFilterTypes.Autocomplete) {
      const sourceProps = sourceFilter.props as IAtocompleteProps;
      props = {
        operator: sourceProps.operator,
        value: { id: sourceProps.value.id, desc: sourceProps.value.desc },
      };
    }
    if (UIFiltersDef[sourceField] === UIFilterTypes.AutocompleteMutiple) {
      const sourceProps = sourceFilter.props as IAtocompleteMultipleProps;
      props = {
        operator: sourceProps.operator,
        value: { id: sourceProps.value.id, desc: sourceProps.value.desc },
      };
    }
    if (UIFiltersDef[sourceField] === UIFilterTypes.AutocompleteMutipleRating) {
      const sourceProps = sourceFilter.props as IAtocompleteMultipleRatingProps;
      props = {
        operator: [sourceProps.operator[0], sourceProps.operator[1]],
        value: [{ id: sourceProps.value[0].id, desc: sourceProps.value[0].desc }, sourceProps.value[1]],
      };
    }
    if (UIFiltersDef[sourceField] === UIFilterTypes.RangeSilderStesRate) {
      const sourceProps = sourceFilter.props as IRangeSilderStesRateProps;
      props = {
        operator: sourceProps.operator,
        value: [sourceProps.value[0], sourceProps.value[1]],
      };
    }
    if (UIFiltersDef[sourceField] === UIFilterTypes.Checkbox) {
      const sourceProps = sourceFilter.props as ICheckboxProps;
      props = {
        operator: sourceProps.operator,
        value: sourceProps.value,
      };
    }
    if (UIFiltersDef[sourceField] === UIFilterTypes.SwitchReverse) {
      const sourceProps = sourceFilter.props as ISwitchReverseProps;
      props = {
        operator: sourceProps.operator,
        value: sourceProps.value,
      };
    }
    const filter = new Filter(sourceFilter.field, props);
    return filter;
  };

  public getId = (): string => {
    if (UIFiltersDef[this.field] === UIFilterTypes.Autocomplete) {
      const props = this.props as IAtocompleteProps;
      return `${this.field}-${props.value.id}`;
    } else if (UIFiltersDef[this.field] === UIFilterTypes.AutocompleteMutiple) {
      const props = this.props as IAtocompleteMultipleProps;
      return `${this.field}-${props.value.id}`;
    } else if (UIFiltersDef[this.field] === UIFilterTypes.AutocompleteMutipleRating) {
      const props = this.props as IAtocompleteMultipleRatingProps;
      return `${this.field}-${props.value[0].id}-${props.value[1]}`;
    } else if (UIFiltersDef[this.field] === UIFilterTypes.RangeSilderStesRate) {
      const props = this.props as IRangeSilderStesRateProps;
      return `${this.field}-${props.operator}-${props.value[0]}-${props.value[1]}`;
    } else if (UIFiltersDef[this.field] === UIFilterTypes.Checkbox) {
      const props = this.props as ICheckboxProps;
      return `${this.field}-${props.value}`;
    } else if (UIFiltersDef[this.field] === UIFilterTypes.SwitchReverse) {
      const props = this.props as ISwitchReverseProps;
      return `${this.field}-${props.value}`;
    }
  };

  public toLabelText = (): string => {
    if (UIFiltersDef[this.field] === UIFilterTypes.Autocomplete) {
      const props = this.props as IAtocompleteProps;
      return `${props.value.desc}`;
    } else if (UIFiltersDef[this.field] === UIFilterTypes.AutocompleteMutiple) {
      const props = this.props as IAtocompleteMultipleProps;
      return useI18N().t(props.value.desc).toString();
    } else if (UIFiltersDef[this.field] === UIFilterTypes.AutocompleteMutipleRating) {
      const props = this.props as IAtocompleteMultipleRatingProps;
      return `${props.value[0].desc}`;
    } else if (UIFiltersDef[this.field] === UIFilterTypes.RangeSilderStesRate) {
      const props = this.props as IRangeSilderStesRateProps;
      return `${props.value[0]}€-${props.value[1]}€`;
    } else if (UIFiltersDef[this.field] === UIFilterTypes.Checkbox) {
      const props = this.props as ICheckboxProps;
      return `${props.value ? useI18N().t('explore.tags.english').toString() : ''}`;
    }
  };

  public updateRating = (rating: number): void => {
    if (UIFiltersDef[this.field] !== UIFilterTypes.AutocompleteMutipleRating) {
      throw new Error('This method is only for AutocompleteMutipleRating filters');
    }

    const props = this.props as IAtocompleteMultipleRatingProps;
    props.value[1] = rating;
  };

  public toAtocompleteState(): IAtocompleteState {
    if (UIFiltersDef[this.field] !== UIFilterTypes.Autocomplete) {
      throw new Error('This method is only for Autocomplete filters');
    }

    const props = this.props as IAtocompleteProps;
    return { id: props.value.id, desc: props.value.desc };
  }

  public toAtocompleteMultipleState(): IAtocompleteMultipleState {
    if (UIFiltersDef[this.field] !== UIFilterTypes.AutocompleteMutiple) {
      throw new Error('This method is only for AutocompleteMutiple filters');
    }

    const props = this.props as IAtocompleteMultipleProps;
    return { id: props.value.id, desc: props.value.desc };
  }

  public toAtocompleteMultipleRatingState(): IAtocompleteMultipleRatingState {
    if (UIFiltersDef[this.field] !== UIFilterTypes.AutocompleteMutipleRating) {
      throw new Error('This method is only for AutocompleteMutipleRating filters');
    }

    const props = this.props as IAtocompleteMultipleRatingProps;
    return { id: props.value[0].id, desc: props.value[0].desc, rating: props.value[1] };
  }

  public toRangeSlderStepsRateState(): IRangeSliderStepsRateState {
    if (UIFiltersDef[this.field] !== UIFilterTypes.RangeSilderStesRate) {
      throw new Error('This method is only for RangeSilderStesRate filters');
    }

    const props = this.props as IRangeSilderStesRateProps;
    return [props.value[0], props.value[1]];
  }

  public toCheckboxState(): boolean {
    if (UIFiltersDef[this.field] !== UIFilterTypes.Checkbox) {
      throw new Error('This method is only for Checkbox filters');
    }

    const props = this.props as ICheckboxProps;
    return props.value;
  }

  public toSwitchReverseState(): boolean {
    if (UIFiltersDef[this.field] !== UIFilterTypes.SwitchReverse) {
      throw new Error('This method is only for SwitchReverse filters');
    }

    const props = this.props as ISwitchReverseProps;
    return props.value;
  }

  public toStandardFilter = (): IStandardFilter[] => {
    if (UIFiltersDef[this.field] === UIFilterTypes.Autocomplete) {
      const props = this.props as IAtocompleteProps;
      return [{ field: this.fieldMapper(this.field), operator: props.operator, value: props.value.id }];
    } else if (UIFiltersDef[this.field] === UIFilterTypes.AutocompleteMutiple) {
      const props = this.props as IAtocompleteMultipleProps;
      return [{ field: this.fieldMapper(this.field), operator: props.operator, value: props.value.id }];
    } else if (UIFiltersDef[this.field] === UIFilterTypes.AutocompleteMutipleRating) {
      const props = this.props as IAtocompleteMultipleRatingProps;
      return [
        { field: this.fieldMapper(this.field), operator: props.operator[0], value: props.value[0].id.toString() },
        { field: this.fieldMapper(this.field), operator: props.operator[1], value: props.value[0].id.toString() },
      ];
    } else if (UIFiltersDef[this.field] === UIFilterTypes.RangeSilderStesRate) {
      const props = this.props as IRangeSilderStesRateProps;
      return [
        { field: this.fieldMapper(this.field), operator: Operators.GreaterThan, value: props.value[0].toString() },
        { field: this.fieldMapper(this.field), operator: Operators.LowerThan, value: props.value[1].toString() },
      ];
    } else if (UIFiltersDef[this.field] === UIFilterTypes.Checkbox) {
      const props = this.props as ICheckboxProps;
      return [{ field: this.fieldMapper(this.field), operator: props.operator, value: props.value.toString() }];
    } else if (UIFiltersDef[this.field] === UIFilterTypes.SwitchReverse) {
      const props = this.props as ISwitchReverseProps;
      return [{ field: this.fieldMapper(this.field), operator: props.operator, value: props.value.toString() }];
    }
  };

  private fieldMapper = (field: string): string => {
    switch (field) {
      case 'avail':
        return 'availability';
      case 'tech':
        return 'technology';
      case 'comp':
        return 'technologyScore';
      case 'sk':
        return 'softSkills';
      case 'cert':
        return 'certifications';
      case 'lang':
        return 'languages';
      case 'rate':
        return 'rate';
      default:
        return field;
    }
  };
}
