import { defineComponent, inject, ref, onMounted, computed, watch, provide } from 'vue';
import FilterTagList from '@/sections/explore/filter-tag-list/FilterTagList.vue';
import MoreFilters from '@/sections/explore/more-filters/MoreFilters.vue';
import TechnologyService from '@/entities/technology/technology.service';
import SoftSkillService from '@/entities/soft-skill/soft-skill.service';
import { useStore } from '@/plugins/vuex';
import { IAtocompleteMultipleState } from '@/components/stemdo-components/s-autocomplete-multiple/sAutocompleteMultiple';
import { IAtocompleteMultipleRatingState } from '@/components/stemdo-components/s-autocomplete-multiple-rating/sAutocompleteMultipleRating';
import { FilterFields } from '../model/Filters';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import { IRate } from '@/shared/model/rate.model';
import { useI18N } from '@/plugins/i18n';
import VerticalService from '@/entities/vertical/vertical.service';
import { IAtocompleteState } from '@/components/stemdo-components/s-autocomplete/sAutocomplete';
import { Vertical } from '@/shared/model/vertical.model';

const MORE_FILTERS_ICON_SRC = '/content/svgs/more-filters.svg';
export default defineComponent({
  components: {
    FilterTagList,
    MoreFilters,
  },
  props: {
    stemdoers: {
      type: Array as () => IStemdoer[],
      required: true,
    },
    rates: {
      type: Array as () => IRate[],
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const i18n = useI18N();
    const verticals = ref<Vertical[]>([]);
    const verticalsWithCommon = ref<Vertical[]>([]);
    const technologyService: TechnologyService = inject('technologyService');
    const softSkillService: SoftSkillService = inject('softSkillService');
    const verticalService: VerticalService = inject('verticalService');
    const openMoreFilters = ref(false);
    const technologiesItems = ref<IAtocompleteMultipleState[]>([]);
    const technologiesField = FilterFields.Technology;
    const defaultTechnology = ref<IAtocompleteState>(undefined);
    const competenciesItems = ref<IAtocompleteMultipleRatingState[]>([]);
    const competenciesItemsByVertical = ref<IAtocompleteMultipleRatingState[]>([]);
    const competenciesItemsGroupedByVertical = ref<IAtocompleteMultipleRatingState[]>([]);
    const competenciesField = FilterFields.Competency;
    const compFilterKey = ref(0);
    const softSkillsItems = ref<IAtocompleteMultipleState[]>([]);
    const isManager = computed(() => store.getters.account?.authorities.includes('ROLE_GESTOR') ?? false);
    const totalFilters = computed(() => {
      return store.getters['filterStore/totalFilters'];
    });
    const totalTags = computed(() => {
      const tags = store.getters['filterStore/filters'];
      return tags.filter(tags => tags.field !== FilterFields.Available && tags.field !== FilterFields.Technology).length;
    });

    onMounted(async () => {
      loadSoftSkills();
      await loadCompetencies();
      await loadTechnologies();

      const defaultVertical = getDefaultVertical();
      if (defaultVertical) defaultTechnology.value = { id: defaultVertical.id, desc: defaultVertical.name };
      filterCompetenciesByVertical(defaultVertical);
    });

    const getDefaultVertical = (): Vertical => {
      const storedVertical = store.getters['filterStore/getAutoCompleteState'](technologiesField);
      if (storedVertical) return verticals.value.find(vertical => vertical.id === storedVertical.id);
      return verticals.value.find(vertical => vertical.name === 'Cloud');
    };

    const clickTechFilter = (field: FilterFields, newSelectedItem: IAtocompleteMultipleState) => {
      const verticalSelected = verticals.value.find(vertical => vertical.id === newSelectedItem.id);
      filterCompetenciesByVertical(verticalSelected);

      const payload = { field, newSelectedItem };
      store.commit('filterStore/updateAutoCompleteState', payload);
    };

    const clickCompFilter = (field: FilterFields, newSelectedItem: IAtocompleteMultipleRatingState) => {
      const payload = { field, newSelectedItem };
      store.commit('filterStore/updateAutoCompleteMultipleRatingState', payload);
    };

    const filterCompetenciesByVertical = (vertical: Vertical) => {
      const techsOfVertical = vertical.technologyIds;
      competenciesItemsByVertical.value = competenciesItems.value.filter(competency => techsOfVertical.includes(competency.id as number));
    };

    const loadCompetencies = async () => {
      const res = await technologyService.retrieve();
      const technologies: any[] = res.data;
      technologies.forEach(tech => {
        const technology = {
          id: tech.id,
          desc: i18n.t(`stemdoPlatformApp.technology.list.${tech.id}`).toString(),
          rating: 0,
        };
        competenciesItems.value.push(technology);
      });
    };

    const loadTechnologies = async () => {
      const res = await verticalService.retrieve();
      const data = res.data;
      data.forEach(vertical => {
        technologiesItems.value.push({ id: vertical.id, desc: vertical.name });
        verticals.value.push(Vertical.toVertical(vertical));
      });
      verticalsWithCommon.value = createVerticals(data);
      competenciesItemsGroupedByVertical.value = groupCompenciesByVertical(verticalsWithCommon.value);
    };

    const createVerticals = (data: any) => {
      const createVerticalWithCommonTechs = (verticals: Vertical[]) => {
        const allTechIds: number[] = [];
        const commonTechIds: number[] = [];
        verticals.forEach(v => {
          v.technologyIds.forEach(techId => {
            if (allTechIds.includes(techId) && !commonTechIds.includes(techId)) commonTechIds.push(techId);
            allTechIds.push(techId);
          });
        });
        return new Vertical('common', 'Common', commonTechIds);
      };
      const verticals: Vertical[] = [];
      const commonVertical = createVerticalWithCommonTechs(data);
      verticals.push(commonVertical);
      data.forEach(vertical => {
        vertical.technologyIds = vertical.technologyIds.filter(techId => !commonVertical.technologyIds.includes(techId));
        verticals.push(Vertical.toVertical(vertical));
      });
      return verticals;
    };

    const groupCompenciesByVertical = (verticals: Vertical[]): IAtocompleteMultipleRatingState[] => {
      const compGroupedByVertical: IAtocompleteMultipleRatingState[] = [];
      verticals.forEach(vertical => {
        //@ts-ignore
        compGroupedByVertical.push({ header: vertical.name });
        vertical.technologyIds.forEach(techId => {
          const tech = competenciesItems.value.find(tech => tech.id === techId);
          if (tech) compGroupedByVertical.push(tech);
        });
      });
      return compGroupedByVertical;
    };

    const loadSoftSkills = async () => {
      softSkillService.retrieve().then(res => {
        const softSkills = res.data;
        softSkills.forEach(skill => {
          softSkillsItems.value.push({ id: skill.id, desc: 'stemdoPlatformApp.softSkill.list.' + skill.externalId });
        });
      });
    };

    const openMoreFiltersModal = () => {
      store.commit('filterStore/resetTempFilters');
      openMoreFilters.value = true;
    };

    const closeMoreFiltersModal = () => {
      openMoreFilters.value = false;

      const el = document.getElementById('modal-centered-content');
      el.scrollIntoView();

      // Update key of comp filter to force re-render and update input label text
      compFilterKey.value += 1;
    };

    const applyFilters = () => {
      store.commit('filterStore/commitTempFilters');
      closeMoreFiltersModal();
    };

    return {
      moreFiltersIconSrc: MORE_FILTERS_ICON_SRC,
      openMoreFilters,
      technologiesField,
      competenciesField,
      technologiesItems,
      defaultTechnology,
      competenciesItems,
      competenciesItemsGroupedByVertical,
      competenciesItemsByVertical,
      softSkillsItems,
      totalFilters,
      totalTags,
      isManager,
      compFilterKey,
      clickTechFilter,
      clickCompFilter,
      openMoreFiltersModal,
      closeMoreFiltersModal,
      applyFilters,
    };
  },
});
