var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { id: "stemdoerList", fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                _vm._l(_vm.stemdoers, function (stemdoer) {
                  return _c(
                    "v-col",
                    {
                      key: stemdoer.id,
                      attrs: { xs: "12", sm: "12", md: "12", lg: "6", xl: "6" },
                    },
                    [
                      _c("StemdoerCard", {
                        attrs: {
                          stemdoer: stemdoer,
                          addStemdoerToCart: _vm.addStemdoerToCart,
                          rates: _vm.rates,
                          openStemdoerDetailModal: _vm.openStemdoerDetailModal,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isStemdoerDetailModalOpen
        ? _c(
            "v-dialog",
            {
              staticClass: "bg-white",
              attrs: { "max-width": "70%" },
              on: { input: _vm.resetOverflow },
              model: {
                value: _vm.isStemdoerDetailModalOpen,
                callback: function ($$v) {
                  _vm.isStemdoerDetailModalOpen = $$v
                },
                expression: "isStemdoerDetailModalOpen",
              },
            },
            [
              _c("StemdoerDetail", {
                attrs: {
                  stemdoer: _vm.selectedStemdoer,
                  closeDialog: _vm.closeStemdoerDetailModal,
                  addStemdoerToCart: _vm.addStemdoerToCart,
                  rates: _vm.rates,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }