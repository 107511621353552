import Vue from 'vue';
import VueI18n, { DateTimeFormats, NumberFormats } from 'vue-i18n';
import JhiFormatter from '@/shared/config/formatter';

Vue.use(VueI18n);
const dateTimeFormats: DateTimeFormats = {
  es: {
    twoDigit: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    twoDigitWithoutYear: {
      month: '2-digit',
      day: '2-digit',
    },
  },
  en: {
    twoDigit: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    twoDigitWithoutYear: {
      month: '2-digit',
      day: '2-digit',
    },
  },
  // jhipster-needle-i18n-language-date-time-format - JHipster will add/remove format options in this object
};

const numberFormats: NumberFormats = {
  en: {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    },
  },
  es: {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    },
  },
};

const i18n = new VueI18n({
  dateTimeFormats,
  numberFormats,
  silentTranslationWarn: true,
  formatter: new JhiFormatter(),
});

export default i18n;
export const useI18N = () => i18n;
