import { LangKey } from './enumerations/lang-key.model';

export interface IExperience {
  id?: string;
  initDate?: Date;
  endDate?: Date;
  title?: string;
  sector?: string;
  language?: LangKey;
  company?: string;
  city?: string;
  country?: string;
  description?: string | null;
  technologies?: string | null;
  comment?: string | null;
  teamRequestId?: string;
  validation?: boolean | null;
}

export class Experience implements IExperience {
  constructor(
    public id?: string,
    public initDate?: Date,
    public endDate?: Date,
    public title?: string,
    public sector?: string,
    public language?: LangKey,
    public company?: string,
    public city?: string,
    public country?: string,
    public description?: string | null,
    public technologies?: string | null,
    public comment?: string | null,
    public teamRequestId?: string | null,
    public validation?: boolean | null
  ) {
    this.validation = this.validation ?? false;
  }
}
