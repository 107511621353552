var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "v-dialog-component",
        "max-width": _vm.maxWidth,
        persistent: _vm.persistent,
      },
      on: { "click:outside": _vm.clickOutside, keydown: _vm.keyDown },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "modal-centered-content" } },
        [
          _c(
            "v-card-title",
            { staticClass: "px-8 pt-4 pb-2" },
            [
              _c(
                "v-container",
                { staticClass: "p-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          { staticClass: "detail-link" },
                          [
                            _c("span", { staticClass: "text-h4" }, [
                              _vm._v(_vm._s(_vm.$t(_vm.title))),
                            ]),
                            _vm._v(" "),
                            _vm._t("title-append"),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto", "align-self": "center" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "close-button mr-n3",
                              on: { click: _vm.close },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  width: 24,
                                  src: "/content/svgs/close-modal.svg",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { class: "px-8 py-" + _vm.newProps.bodyPaddingY },
            [_vm._t("body")],
            2
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "px-8 py-5" },
            [
              _c(
                "v-container",
                { staticClass: "p-0" },
                [
                  _vm.newProps.fullWidthFooter === false
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [_vm._t("footer")],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.newProps.fullWidthFooter === true
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_vm._t("footer")],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }