import { IStemdoer } from '@/shared/model/stemdoer.model';
import { RequestStatus } from './enumerations/request-status.model';
import { IClient } from './client.model';
import { IStemdoerRate } from './stemdoer-rate.model';

export interface ITeamRequestProposal {
  id?: string;
  createdAt?: Date;
  name?: string | null;
  userId?: string | null;
  initDate?: Date | null;
  endDate?: Date | null;
  jobDescription?: string | null;
  hourlyRate?: number | null;
  validation?: boolean | null;
  stemdoers?: IStemdoer[];
  stemdoerRates?: IStemdoerRate[] | null;
  client?: IClient;
  status?: RequestStatus | null;
}

export class TeamRequestProposal implements ITeamRequestProposal {
  constructor(
    public id?: string,
    public createdAt?: Date,
    public name?: string | null,
    public userId?: string | null,
    public initDate?: Date | null,
    public endDate?: Date | null,
    public jobDescription?: string | null,
    public hourlyRate?: number | null,
    public validation?: boolean | null,
    public stemdoers?: IStemdoer[],
    public client?: IClient,
    status?: RequestStatus | null
  ) {
    this.validation = this.validation ?? false;
  }
}
