import { IStemdoer } from '@/shared/model/stemdoer.model';

export interface IVertical {
  id?: string;
  name?: string;
  technologyIds?: number[] | null;
}

export class Vertical implements IVertical {
  constructor(public id?: string, public name?: string, public technologyIds?: number[] | null) {}

  static toVertical(json: any): Vertical {
    return new Vertical(json.id, json.name, json.technologyIds);
  }
}
