import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    sText: {
      type: String as PropType<string>,
      required: true,
    },
    isValid: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const handleClose = () => {
      emit('close');
    };

    return {
      handleClose,
    };
  },
});
