import { defineComponent, inject, onMounted, ref, Ref } from 'vue';
import ClientService from '@/entities/client/client.service';
import { Client } from '@/shared/model/client.model';
import { useI18N } from '@/plugins/i18n';

import { useStore } from '@/plugins/vuex';

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    defaultSelectedItem: {
      type: Object as () => Client,
      required: false,
    },
  },
  setup(props) {
    const i18n = useI18N();
    const placeholderSelect = `${i18n.t(props.placeholder).toString()}`;
    const selectedItem = ref(undefined);
    const store = useStore();
    const clientService = inject('clientService') as () => ClientService;
    const clients: Ref<Client[]> = ref([]);
    const clientStored = localStorage.getItem('clientSelected');

    onMounted(() => {
      retrieveAllClients();

      if (props.defaultSelectedItem) {
        selectedItem.value = props.defaultSelectedItem;
        console.log('defaultSelectedItem', props.defaultSelectedItem);
      } else {
        selectedItem.value = JSON.parse(clientStored);
      }
    });

    const retrieveAllClients = async () => {
      try {
        const response = await clientService().retrieveWithRates();
        clients.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const itemText = (item: Client): string => {
      return `${item.name} ${item.surname}`;
    };

    const onClientSelected = (selectedClient: Client) => {
      addClientToStore(selectedClient);
    };

    const addClientToStore = (client: Client) => {
      store.commit('cartStore/setClient', client);
    };

    return {
      clients,
      placeholderSelect,
      clientStored,
      selectedItem,
      itemText,
      onClientSelected,
    };
  },
});
