var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("span", { staticClass: "language-icon" }, [
    _c("img", {
      attrs: {
        src: `content/svgs/${_vm.langValue}-flag.svg`,
        alt: _vm.language,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }