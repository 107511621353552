import { Experience, IExperience } from './experience.model';
import moment from 'moment';

type ExperienceGroupedByCompany = {
  company: string;
  totalTime: number;
  experiences: Experience[];
  size: number;
};

export class ExperienceCollection extends Array<Experience> {
  constructor(experiences: IExperience[]) {
    super();
    for (const experience of experiences) {
      this.push(
        new Experience(
          experience.id,
          experience.initDate,
          experience.endDate,
          experience.title,
          experience.sector,
          experience.language,
          experience.company,
          experience.city,
          experience.country,
          experience.description,
          experience.technologies,
          experience.comment,
          experience.teamRequestId,
          experience.validation
        )
      );
    }
  }

  sortByDateDesc = () => {
    this.sort((a, b) => {
      if (a.initDate && b.initDate) {
        return a.initDate > b.initDate ? -1 : 1;
      }
      return 0;
    });
  };

  groupByCompany = () => {
    this.sortByDateDesc();
    let lastCompany = null;

    const grouped = this.reduce((grouped, experience) => {
      const key = experience.company;
      const initDate = moment(experience.initDate);
      const endDate = experience.endDate ? moment(experience.endDate) : moment();

      const experienceTime = Math.round(endDate.diff(initDate, 'months', true));

      if (lastCompany === key) {
        grouped[grouped.length - 1].experiences.push(experience);
        grouped[grouped.length - 1].totalTime += experienceTime;
        grouped[grouped.length - 1].size += 1;
      } else {
        grouped.push({
          company: key,
          totalTime: experienceTime,
          experiences: [experience],
          size: 1,
        });
      }
      lastCompany = key;
      return grouped;
    }, []);

    grouped.forEach(group => {
      group.totalTime = Math.round(group.totalTime);
    });

    return grouped;
  };
}
