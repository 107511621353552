import { useStore } from '@/plugins/vuex';
import { Authority } from '@/shared/security/authority';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';
import MainDrawer from '@/components/main-drawer/MainDrawer.vue';
import MainContentWrapper from '@/components/main-content-wrapper/MainContentWrapper.vue';
import accountPages from '@/router/account';

export default {
  components: {
    Ribbon,
    JhiNavbar,
    MainDrawer,
    MainContentWrapper,
  },
  setup() {
    const store = useStore();

    const isClientRole = (): boolean => {
      const authorities = store.getters.account?.authorities;
      if (authorities) {
        return authorities.includes(Authority.CLIENT);
      }

      return false;
    };
    const isGestorRole = (): boolean => {
      const authorities = store.getters.account?.authorities;
      if (authorities) {
        return authorities.includes(Authority.GESTOR);
      }

      return false;
    };

    const isAuthenticated = () => {
      return store.getters.authenticated;
    };

    const showNavBar = (): boolean => {
      const path = window.location.pathname;
      if (accountPages.find(page => page.path === path) || path === '/') return false;
      return true;
    };

    const showNewNavbar = (): boolean => {
      const res = isClientRole() || isGestorRole();
      return res;
    };

    return { showNavBar, isAuthenticated, showNewNavbar };
  },
};
