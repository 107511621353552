import { defineComponent } from 'vue';
import useRouter from 'vue-router';

export default defineComponent({
  setup() {
    const router = new useRouter();

    const goBack = async (): Promise<void> => {
      try {
        await router.back();
      } catch (error) {
        console.error('Error al navegar hacia atrás:', error);
      }
    };

    return {
      goBack,
    };
  },
});
