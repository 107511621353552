var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "filter-tag px-3 py-2 d-inline-flex align-center" },
    [
      _c("div", { staticClass: "pl-1 mr-1 font-weight-regular" }, [
        _vm._v(_vm._s(_vm.tagText)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tag-close", on: { click: _vm.handleClose } },
        [
          _c("v-img", {
            attrs: { width: 26, src: "/content/svgs/close-tag.svg" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }