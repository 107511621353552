var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "mx-0 mb-8 pl-0 pt-0",
      attrs: { id: "candidate-list", fluid: "" },
    },
    [
      _c("v-data-table", {
        staticClass: "remove-box-shadow candidate-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.candidates,
          "hide-default-footer": "",
          "disable-pagination": "",
          "show-select": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item.fullName",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "pl-0 td-custom" },
                  [
                    _c("StemdoerAvatar", {
                      attrs: { stemdoId: item.stemdoId, size: "21px" },
                    }),
                    _vm._v("\n        " + _vm._s(item.fullName) + "\n      "),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.rate",
            fn: function ({ item }) {
              return [_vm._v(" " + _vm._s(item.rate) + "€ ")]
            },
          },
          {
            key: "item.team",
            fn: function ({ item }) {
              return [
                _c("div", { staticClass: "team-status" }, [
                  _c("span", {
                    staticClass: "text-body-3 p-1 pl-2 pr-2",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("candidate.table.status.unassigned")
                      ),
                    },
                  }),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }