import { render, staticRenderFns } from "./SAutocompleteMultiple.vue?vue&type=template&id=039cc838&scoped=true"
import script from "./sAutocompleteMultiple.ts?vue&type=script&lang=ts&external"
export * from "./sAutocompleteMultiple.ts?vue&type=script&lang=ts&external"
import style0 from "./SAutocompleteMultiple.vue?vue&type=style&index=0&id=039cc838&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "039cc838",
  null
  
)

export default component.exports