import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import SButtonBack from '@/components/stemdo-components/s-button-back/SButtonBack.vue';
import TeamRequestService from '@/entities/team-request/team-request.service';
import TeamDetail from './team-detail/TeamDetail.vue';
import router from '@/router';
import { ITeamRequest } from '@/shared/model/team-request.model';
import { IStemdoerRate } from '@/shared/model/stemdoer-rate.model';

export default defineComponent({
  components: {
    SButtonBack,
    TeamDetail,
  },
  setup() {
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const teamRequest = ref<ITeamRequest>(null);
    const originalStemdoerRates = ref<IStemdoerRate[]>([]);
    const isAnyRateChanged = computed(() => {
      return teamRequest.value.stemdoerRates.some(sr => {
        const originalRate = originalStemdoerRates.value.find(osr => osr.stemdoer.id === sr.stemdoer.id).rate;
        return sr.rate !== originalRate;
      });
    });

    onMounted(() => {
      const id = router.currentRoute.params['id'].toString();
      retrieveTeamRequest(id);
    });

    const retrieveTeamRequest = id => {
      teamRequestService.find(id).then(tr => {
        teamRequest.value = tr;
        originalStemdoerRates.value = tr.stemdoerRates?.map(sr => Object.assign({}, sr));
      });
    };

    const updateStemdoerRate = (stemdoerId: string, newRate: number) => {
      teamRequest.value.stemdoerRates.find(sr => sr.stemdoer.id === stemdoerId).rate = newRate;
    };

    const refreshTeamRequest = () => {
      retrieveTeamRequest(teamRequest.value.id);
    };

    return { teamRequest, isAnyRateChanged, updateStemdoerRate, refreshTeamRequest };
  },
});
