var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "mx-0 top-filter-wrapper custom-container" },
    [
      _c(
        "v-row",
        { staticClass: "d-flex p-2", staticStyle: { "min-height": "85px" } },
        [
          _c(
            "v-col",
            { staticClass: "text-left p-0 pl-4", attrs: { cols: "12" } },
            [
              _c("span", {
                domProps: { textContent: _vm._s(_vm.$t("requests.client")) },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "p-1", attrs: { cols: "12" } },
            [
              _c("v-autocomplete", {
                staticClass: "custom-v-autocomplete",
                attrs: {
                  placeholder: _vm.placeholderSelect,
                  items: _vm.clients,
                  "item-text": _vm.itemText,
                  "return-object": "",
                  labelDisplaySelection: true,
                  inputHeight: 45,
                  dense: "",
                  flat: "",
                  solo: "",
                },
                on: { input: _vm.onClientSelected },
                model: {
                  value: _vm.selectedItem,
                  callback: function ($$v) {
                    _vm.selectedItem = $$v
                  },
                  expression: "selectedItem",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }