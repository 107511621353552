<template>
  <div class="d-flex flex-row align-center w-100">
    <div :class="isActive || hover ? 'vl-color' : 'vl'"></div>
    <v-list-item-icon class="ml-2 mr-4">
      <v-img
        :width="24"
        :src="'/content/svgs/' + (item.disable ? item.iconDisableName : isActive || hover ? item.iconActiveName : item.iconName) + '.svg'"
      ></v-img>
    </v-list-item-icon>
    <v-list-item-content class="py-1">
      <v-list-item-title
        :class="['py-1', item.disable ? 'text-body-1 text--text text--lighten-1' : '', hover ? 'hover-item-content__title' : '']"
      >
        {{ $t(item.title) }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-icon v-if="item.disable">
      <v-img :width="24" :src="'/content/svgs/lock.svg'"></v-img>
    </v-list-item-icon>
    <BadgetTest :item="item" />
  </div>
</template>

<script lang="ts">
import BadgetTest from './BadgetTest.vue';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    hover: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BadgetTest,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
/* Style for vertical line before elements in the nav */
.vl-color {
  background-color: var(--v-primary-base);
  width: 3px;
  height: 40px;
}

.vl {
  background-color: transparent;
  width: 3px;
  height: 40px;
}
</style>
