var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-select", {
    staticClass: "custom-v-select",
    attrs: {
      items: _vm.languages,
      "item-value": "id",
      "item-text": "desc",
      flat: "",
      dense: "",
      solo: "",
    },
    on: { change: _vm.changeLanguage },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ item }) {
          return [
            _c(
              "div",
              { staticClass: "mr-2" },
              [
                _c("v-img", {
                  attrs: {
                    maxWidth: 24,
                    src: "/content/svgs/" + item.svg + ".svg",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text--text text--lighten-1 font-weight-medium" },
              [_vm._v(_vm._s(item.desc))]
            ),
          ]
        },
      },
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "div",
              { staticClass: "mr-2" },
              [
                _c("v-img", {
                  attrs: {
                    maxWidth: 24,
                    src: "/content/svgs/" + item.svg + ".svg",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text--text text--lighten-1 font-weight-medium" },
              [_vm._v(_vm._s(item.desc))]
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.currentLanguage,
      callback: function ($$v) {
        _vm.currentLanguage = $$v
      },
      expression: "currentLanguage",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }