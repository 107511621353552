var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c("ribbon"),
      _vm._v(" "),
      _vm.isAuthenticated() & _vm.showNavBar() && !_vm.showNewNavbar()
        ? _c("div", { attrs: { id: "app-header" } }, [_c("jhi-navbar")], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.isAuthenticated() & _vm.showNavBar() && _vm.showNewNavbar()
        ? _c("MainDrawer")
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-main",
        [
          _vm.showNavBar() && _vm.showNewNavbar()
            ? _c("MainContentWrapper", [_vm._t("default")], 2)
            : _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }