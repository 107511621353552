var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-avatar",
    {
      staticStyle: { position: "relative" },
      attrs: { size: _vm.size, id: "StemdoerAvatarImgFrame" },
    },
    [
      _vm.isLoaded && _vm.isBlobUrl
        ? _c("v-img", {
            attrs: {
              src: _vm.stemdoerImg,
              "lazy-src": "/content/images/bg_profile_without_stemdoer.png",
            },
          })
        : _vm.isLoaded
        ? [
            _c(
              "v-img",
              [
                _c(
                  "v-overlay",
                  { attrs: { absolute: "", color: "transparent" } },
                  [
                    _c("span", {
                      staticClass: "overlay-text",
                      domProps: {
                        textContent: _vm._s(_vm.$t(_vm.stemdoerImg)),
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }