var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "popup",
      class: { Sconfirm: _vm.isValid, Serror: !_vm.isValid },
    },
    [
      _c(
        "svg",
        {
          staticClass: "close-button",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            fill: "none",
          },
          on: { click: _vm.handleClose },
        },
        [
          _c("g", { attrs: { "clip-path": "url(#clip0_656_7008)" } }, [
            _c("path", {
              attrs: {
                d: "M13.41 11.9999L17.71 7.70994C17.8983 7.52164 18.0041 7.26624 18.0041 6.99994C18.0041 6.73364 17.8983 6.47825 17.71 6.28994C17.5217 6.10164 17.2663 5.99585 17 5.99585C16.7337 5.99585 16.4783 6.10164 16.29 6.28994L12 10.5899L7.71 6.28994C7.5217 6.10164 7.2663 5.99585 7 5.99585C6.7337 5.99585 6.4783 6.10164 6.29 6.28994C6.1017 6.47825 5.99591 6.73364 5.99591 6.99994C5.99591 7.26624 6.1017 7.52164 6.29 7.70994L10.59 11.9999L6.29 16.2899C6.19627 16.3829 6.12188 16.4935 6.07111 16.6154C6.02034 16.7372 5.9942 16.8679 5.9942 16.9999C5.9942 17.132 6.02034 17.2627 6.07111 17.3845C6.12188 17.5064 6.19627 17.617 6.29 17.7099C6.38296 17.8037 6.49356 17.8781 6.61542 17.9288C6.73728 17.9796 6.86799 18.0057 7 18.0057C7.13201 18.0057 7.26272 17.9796 7.38458 17.9288C7.50644 17.8781 7.61704 17.8037 7.71 17.7099L12 13.4099L16.29 17.7099C16.383 17.8037 16.4936 17.8781 16.6154 17.9288C16.7373 17.9796 16.868 18.0057 17 18.0057C17.132 18.0057 17.2627 17.9796 17.3846 17.9288C17.5064 17.8781 17.617 17.8037 17.71 17.7099C17.8037 17.617 17.8781 17.5064 17.9289 17.3845C17.9797 17.2627 18.0058 17.132 18.0058 16.9999C18.0058 16.8679 17.9797 16.7372 17.9289 16.6154C17.8781 16.4935 17.8037 16.3829 17.71 16.2899L13.41 11.9999Z",
                fill: "#606061",
              },
            }),
          ]),
          _vm._v(" "),
          _c("defs", [
            _c("clipPath", { attrs: { id: "clip0_656_7008" } }, [
              _c("rect", {
                attrs: { width: "24", height: "24", fill: "white" },
              }),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            staticClass: "status",
            class: { Sconfirm: _vm.isValid, Serror: !_vm.isValid },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "40",
                  height: "40",
                  viewBox: "0 0 41 41",
                  fill: "none",
                },
              },
              [
                _c("circle", { attrs: { cx: "20", cy: "20", r: "20" } }),
                _vm._v(" "),
                !_vm.isValid
                  ? _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "20",
                          height: "20",
                          viewBox: "0 0 20 20",
                          fill: "none",
                          x: "10",
                          y: "10",
                          transform: "translate(-10, -10)",
                        },
                      },
                      [
                        _c(
                          "g",
                          { attrs: { "clip-path": "url(#clip0_656_7218)" } },
                          [
                            _c("path", {
                              attrs: {
                                d: "M11.175 10L14.7583 6.425C14.9153 6.26808 15.0034 6.05525 15.0034 5.83333C15.0034 5.61141 14.9153 5.39859 14.7583 5.24167C14.6014 5.08475 14.3886 4.99659 14.1667 4.99659C13.9448 4.99659 13.7319 5.08475 13.575 5.24167L10 8.825L6.42501 5.24167C6.26809 5.08475 6.05526 4.99659 5.83334 4.99659C5.61143 4.99659 5.3986 5.08475 5.24168 5.24167C5.08476 5.39859 4.9966 5.61141 4.9966 5.83333C4.9966 6.05525 5.08476 6.26808 5.24168 6.425L8.82501 10L5.24168 13.575C5.16357 13.6525 5.10157 13.7446 5.05927 13.8462C5.01696 13.9477 4.99518 14.0567 4.99518 14.1667C4.99518 14.2767 5.01696 14.3856 5.05927 14.4871C5.10157 14.5887 5.16357 14.6809 5.24168 14.7583C5.31915 14.8364 5.41131 14.8984 5.51286 14.9407C5.61441 14.9831 5.72333 15.0048 5.83334 15.0048C5.94335 15.0048 6.05227 14.9831 6.15382 14.9407C6.25537 14.8984 6.34754 14.8364 6.42501 14.7583L10 11.175L13.575 14.7583C13.6525 14.8364 13.7446 14.8984 13.8462 14.9407C13.9477 14.9831 14.0567 15.0048 14.1667 15.0048C14.2767 15.0048 14.3856 14.9831 14.4872 14.9407C14.5887 14.8984 14.6809 14.8364 14.7583 14.7583C14.8364 14.6809 14.8984 14.5887 14.9408 14.4871C14.9831 14.3856 15.0048 14.2767 15.0048 14.1667C15.0048 14.0567 14.9831 13.9477 14.9408 13.8462C14.8984 13.7446 14.8364 13.6525 14.7583 13.575L11.175 10Z",
                                fill: "white",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("defs", [
                          _c("clipPath", { attrs: { id: "clip0_656_7218" } }, [
                            _c("rect", {
                              attrs: {
                                width: "20",
                                height: "20",
                                fill: "white",
                              },
                            }),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isValid
                  ? _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "20",
                          height: "20",
                          viewBox: "0 0 20 20",
                          fill: "none",
                          x: "10",
                          y: "10",
                          transform: "translate(-10, -10)",
                        },
                      },
                      [
                        _c(
                          "g",
                          { attrs: { "clip-path": "url(#clip0_656_7004)" } },
                          [
                            _c("path", {
                              attrs: {
                                d: "M8.21668 15C8.10241 14.9997 7.98945 14.9758 7.88479 14.9299C7.78014 14.8841 7.68604 14.8172 7.60834 14.7334L3.55834 10.425C3.40695 10.2637 3.32585 10.0488 3.33288 9.8277C3.33991 9.60656 3.4345 9.39728 3.59584 9.24588C3.75718 9.09449 3.97206 9.01338 4.19319 9.02042C4.41433 9.02745 4.62362 9.12204 4.77501 9.28338L8.20834 12.9417L15.2167 5.27505C15.2878 5.18648 15.3763 5.11339 15.4767 5.06027C15.5771 5.00714 15.6873 4.9751 15.8005 4.96611C15.9137 4.95713 16.0276 4.97138 16.1351 5.00801C16.2426 5.04463 16.3415 5.10285 16.4257 5.17909C16.5099 5.25532 16.5777 5.34797 16.6248 5.45133C16.6719 5.5547 16.6973 5.6666 16.6996 5.78016C16.7019 5.89373 16.6809 6.00656 16.638 6.11172C16.5951 6.21689 16.5311 6.31217 16.45 6.39171L8.83334 14.725C8.75638 14.8103 8.66261 14.8788 8.55792 14.9261C8.45323 14.9735 8.33989 14.9986 8.22501 15H8.21668Z",
                                fill: "white",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("defs", [
                          _c("clipPath", { attrs: { id: "clip0_656_7004" } }, [
                            _c("rect", {
                              attrs: {
                                width: "20",
                                height: "20",
                                fill: "white",
                              },
                            }),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "message" }, [
          _vm._v("\n      " + _vm._s(_vm.$t(_vm.sText)) + "\n    "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }