var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-btn",
    {
      class: "text-none s-button-" + _vm.getBtnStyle(),
      attrs: {
        depressed: _vm.isDepressed,
        outlined: _vm.isOutlined,
        disabled: _vm.disabled,
        block: "",
      },
      on: { click: _vm.action },
    },
    [
      _vm.iconSrc
        ? _c(
            "div",
            { staticClass: "mr-2" },
            [_c("v-img", { attrs: { width: 24, src: _vm.iconSrc } })],
            1
          )
        : _vm._e(),
      _vm._v("\n\n  " + _vm._s(_vm.$t(_vm.sText)) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }