var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mr-6", attrs: { id: "team-detail" } },
    [
      _c(
        "v-row",
        { staticClass: "pa-0 ma-0" },
        [
          _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "12" } }, [
            _c("div", { staticClass: "box px-3 py-1 mb-4" }, [
              _c("span", {
                staticClass: "title-info text-body-2-medium mt-2 ml-2",
                domProps: {
                  textContent: _vm._s(_vm.$t("managerCheckout.client")),
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text-h4 span-info ml-2" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.clientSelected.name +
                        " " +
                        _vm.clientSelected.surname +
                        (_vm.clientSelected.company
                          ? " - " + _vm.clientSelected.company.name
                          : "")
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "6" } }, [
            _c("div", { staticClass: "box px-3 py-1 mr-4" }, [
              _c("span", {
                staticClass: "title-info text-body-2-medium mt-2 ml-2",
                domProps: {
                  textContent: _vm._s(_vm.$t("managerCheckout.daily-cost")),
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text-h4 span-info ml-2" }, [
                _vm._v(
                  " " + _vm._s(_vm.$n(_vm.hourlyCost * 8, "currency")) + " "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "6" } }, [
            _c("div", { staticClass: "box px-3 py-1" }, [
              _c("span", {
                staticClass: "title-info text-body-2-medium mt-2 ml-2",
                domProps: {
                  textContent: _vm._s(_vm.$t("managerCheckout.monthly-cost")),
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text-h4 span-info ml-2" }, [
                _vm._v(
                  _vm._s(_vm.$n(_vm.hourlyCost * 8 * 20, "currency")) + " "
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "pa-0 ma-0" },
        [
          _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "mt-6" },
              [
                _c("SButton", {
                  attrs: {
                    sText: _vm.$t("managerCheckout.Approve"),
                    btnStyle: "primary",
                    action: () => _vm.openFormModalHandler(),
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          title: _vm.$t("managerCheckout.confirm-modal.title"),
          isOpen: _vm.openFormModal,
          closeModal: () => {
            _vm.openFormModal = false
          },
          customProps: { bodyPaddingY: 0 },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "mt-2" }, [
                  _c("span", {
                    staticClass: "modal-span text-subtitle-5",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("label.teams.selected.confirmName")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.teamRequestName,
                        expression: "teamRequestName",
                      },
                    ],
                    ref: "teamRequestNameRef",
                    staticClass: "input-form-stemdo mt-2",
                    attrs: {
                      id: "teamRequestName",
                      type: "text",
                      placeholder: _vm
                        .$t("label.teams.selected.namePlaceholder")
                        .toString(),
                    },
                    domProps: { value: _vm.teamRequestName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.teamRequestName = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                !_vm.showEndDate
                  ? _c("div", { staticClass: "span-option mt-3" }, [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("managerCheckout.confirm-modal.expandTeam")
                          ),
                        },
                        on: {
                          click: () => {
                            _vm.showExpandTeam = true
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.showExpandTeam
                        ? _c(
                            "button",
                            {
                              staticClass: "modal-span text-body-3",
                              on: {
                                click: () => {
                                  _vm.showExpandTeam = false
                                },
                              },
                            },
                            [_vm._v("\n          ❌\n        ")]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "date-container mt-4" }, [
                  _c(
                    "div",
                    { staticClass: "dateInputs" },
                    [
                      _c(
                        "span",
                        { staticClass: "modal-span text-subtitle-5" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("label.teams.selected.initDate"))
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "return-value": _vm.initDate,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          on: {
                            "update:returnValue": function ($event) {
                              _vm.initDate = $event
                            },
                            "update:return-value": function ($event) {
                              _vm.initDate = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "no-hover",
                                          attrs: {
                                            type: "text",
                                            readonly: "",
                                            outlined: "",
                                            "prepend-inner-icon":
                                              "mdi-calendar",
                                            placeholder: _vm.formattedStartDate
                                              ? ""
                                              : _vm.$t(
                                                  "label.teams.selected.initDateTip"
                                                ),
                                          },
                                          model: {
                                            value: _vm.formattedStartDate,
                                            callback: function ($$v) {
                                              _vm.formattedStartDate = $$v
                                            },
                                            expression: "formattedStartDate",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.menuStartDate,
                            callback: function ($$v) {
                              _vm.menuStartDate = $$v
                            },
                            expression: "menuStartDate",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c("v-date-picker", {
                            attrs: {
                              "no-title": "",
                              scrollable: "",
                              min: _vm.initDate,
                              max: _vm.endDate,
                            },
                            on: {
                              input: () => {
                                _vm.checkWrongDate()
                                _vm.menuStartDate = false
                              },
                            },
                            model: {
                              value: _vm.startDate,
                              callback: function ($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.showEndDate
                    ? _c("div", { staticClass: "span-option" }, [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("label.teams.selected.enterEndDate")
                            ),
                          },
                          on: {
                            click: () => {
                              _vm.toggleEndDateInput()
                              _vm.showEndDate = true
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showEndDate
                    ? _c(
                        "div",
                        { staticClass: "dateInputs" },
                        [
                          _c("span", {
                            staticClass: "modal-span text-subtitle-5",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("label.teams.selected.endDate")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.endDate,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.endDate = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.endDate = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            {
                                              staticClass: "no-hover",
                                              attrs: {
                                                type: "text",
                                                readonly: "",
                                                outlined: "",
                                                "prepend-inner-icon":
                                                  "mdi-calendar",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "append",
                                                    fn: function () {
                                                      return [
                                                        _c("div", {
                                                          staticClass:
                                                            "clear-end-date-btn",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "label.teams.selected.delete"
                                                              )
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.clearFinishDate.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.formattedEndDate,
                                                callback: function ($$v) {
                                                  _vm.formattedEndDate = $$v
                                                },
                                                expression: "formattedEndDate",
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2263089811
                              ),
                              model: {
                                value: _vm.menuEndDate,
                                callback: function ($$v) {
                                  _vm.menuEndDate = $$v
                                },
                                expression: "menuEndDate",
                              },
                            },
                            [
                              _vm._v(" "),
                              _c("v-date-picker", {
                                attrs: {
                                  "no-title": "",
                                  scrollable: "",
                                  min: _vm.startDate,
                                  max: _vm.maxEndDate,
                                },
                                on: { input: _vm.checkWrongDate },
                                model: {
                                  value: _vm.finishDate,
                                  callback: function ($$v) {
                                    _vm.finishDate = $$v
                                  },
                                  expression: "finishDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "jobDescription mt-3" }, [
                  _c("span", {
                    staticClass: "modal-span text-subtitle-5",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("managerCheckout.confirm-modal.message")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.teamRequestDescription,
                        expression: "teamRequestDescription",
                      },
                    ],
                    ref: "teamRequestDescriptionRef",
                    staticClass: "input-form-stemdo mt-2",
                    staticStyle: {
                      "min-height": "135px",
                      "max-height": "135px",
                      "flex-shrink": "0",
                      resize: "none",
                    },
                    attrs: {
                      id: "teamRequestDescription",
                      placeholder: _vm
                        .$t("label.teams.selected.jobDescription")
                        .toString(),
                    },
                    domProps: { value: _vm.teamRequestDescription },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.teamRequestDescription = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-6" },
                  [
                    _c("SButton", {
                      attrs: {
                        disabled: _vm.isButtonDisabled,
                        btnStyle: "primary",
                        action: () => {
                          _vm.openFormModal = false
                          _vm.checkWrongDate()
                          _vm.openApproveModalHandler()
                        },
                        sText: _vm.$t("label.teams.selected.confirmBtn"),
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          title: _vm.$t("managerCheckout.modal-title-accept"),
          isOpen: _vm.openApproveModal,
          closeModal: () => {
            _vm.openApproveModal = false
            _vm.clearCart()
          },
          customProps: { bodyPaddingY: 0 },
        },
        scopedSlots: _vm._u([
          {
            key: "title-append",
            fn: function () {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      right: "",
                      "content-class": "primary tooltip-right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-img",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "link-button ml-3",
                                    attrs: {
                                      width: 22,
                                      src: "/content/svgs/link-2-outline1.svg",
                                    },
                                    on: { click: _vm.copyToClipboard },
                                  },
                                  "v-img",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("requests.info-box.saved.share"))),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "text-body-1 mb-4" }, [
                  _vm._v(_vm._s(_vm.$t("managerCheckout.modal-body-accept"))),
                ]),
                _vm._v(" "),
                _c("SButton", {
                  attrs: {
                    action: () => {
                      _vm.openApproveModal = false
                      _vm.clearCart()
                    },
                    sText: _vm.$t("managerCheckout.modal-btn-accept"),
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }