import { render, staticRenderFns } from "./MainContentWrapper.vue?vue&type=template&id=c65cce68&scoped=true"
var script = {}
import style0 from "./MainContentWrapper.vue?vue&type=style&index=0&id=c65cce68&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c65cce68",
  null
  
)

export default component.exports