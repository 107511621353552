const localStorageFacade = {
  set: (key: StorageKey, value: any) => {
    if (key === null || key === undefined) return;
    if (value === null || value === undefined) return;

    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    localStorage.setItem(key, value);
  },
  get: (key: StorageKey) => {
    let value = localStorage.getItem(key);
    if (value === null || value === undefined) return null;

    if (value[0] === '{') {
      value = JSON.parse(value);
    }

    return value;
  },
  remove: (key: StorageKey) => {
    localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  },
  clearSession: () => {
    localStorage.removeItem(StorageKey.CART);
    localStorage.removeItem(StorageKey.FILTER_STORE);
    localStorage.removeItem(StorageKey.AUTH_TOKEN);
    localStorage.removeItem(StorageKey.CLIENT_SELECTED);
  },
};

export enum StorageKey {
  AUTH_TOKEN = 'auth-token',
  REQUESTED_URL = 'requested-url',
  CART = 'cart',
  CLIENT_SELECTED = 'clientSelected',
  FILTER_STORE = 'filterStore',
}

export default localStorageFacade;
