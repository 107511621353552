var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mt-12" }, [
    _c(
      "div",
      { attrs: { id: "noContent" } },
      [
        _c("v-img", {
          staticClass: "mb-3",
          attrs: { width: 30, src: "/content/svgs/9-box.svg" },
        }),
        _vm._v(" "),
        _c("p", {
          staticClass: "text-body-1-bold text-no-content-title",
          domProps: {
            textContent: _vm._s(_vm.$t("no-content-explorer.title-text")),
          },
        }),
        _vm._v(" "),
        _c("p", {
          staticClass: "text-no-content text-subtitle-3",
          domProps: {
            textContent: _vm._s(_vm.$t("no-content-explorer.description-01")),
          },
        }),
        _vm._v(" "),
        _c("p", {
          staticClass: "text-no-content text-subtitle-3",
          domProps: {
            textContent: _vm._s(_vm.$t("no-content-explorer.description-02")),
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { width: "378px" } },
          [
            _c("SButton", {
              attrs: {
                btnStyle: "secondary",
                sText: _vm.$t("no-content-explorer.button-text"),
                action: _vm.notifyAndCleanFilters,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }