/* eslint-disable prettier/prettier */
import { defineComponent, ref } from 'vue';
import { useRouter } from '@/plugins/router';
import ItemContent from './ItemContent.vue';
import ItemGroup from './ItemGroup.vue';

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    ItemGroup,
    ItemContent,
  },
  setup() {
    const router = useRouter();

    const isActiveLink = (paths: string[], currentPath: string) => {
      let isActive = false;
      paths?.forEach(path => {
        if (currentPath.startsWith(path)) isActive = true;
      });
      return isActive;
    };

    const goToPage = (path: string, currentPath: string) => {
      if (currentPath === path) return;
      router.push(path);
    };

    return { isActiveLink, goToPage };
  },
});
