const clientItems = [
  {
    title: 'nav.dashboard',
    iconName: 'home',
    iconActiveName: 'home-blue',
    iconDisableName: 'home-lighten',
    path: '',
    activePaths: [],
    disable: true,
    isTest: false,
  },
  {
    title: 'nav.explore',
    iconName: 'explore',
    iconActiveName: 'explore-blue',
    iconDisableName: 'explore-lighten',
    path: '/explore',
    activePaths: ['/explore', '/checkout'],
    disable: false,
    isTest: false,
  },
  {
    title: 'nav.requests',
    iconName: 'requests',
    iconActiveName: 'requests-blue',
    iconDisableName: 'requests-lighten',
    path: '/team-requests',
    activePaths: ['/team-requests'],
    disable: false,
    isTest: false,
  },
  {
    title: 'nav.myTeams',
    iconName: 'team',
    iconActiveName: 'team-blue',
    iconDisableName: 'team-lighten',
    disable: false,
    isTest: false,
    items: [
      {
        title: 'nav.myTeamsTeams',
        iconActiveName: 'team-blue',
        iconDisableName: 'team-lighten',
        path: '',
        activePaths: [],
        disable: true,
        isTest: false,
      },
      {
        title: 'nav.myTeamsCandidates',
        iconActiveName: 'team-blue',
        iconDisableName: 'team-lighten',
        path: '/teams/candidates',
        activePaths: ['/teams/candidates'],
        disable: false,
        isTest: false,
      },
      {
        title: 'nav.myTeamsCalendar',
        iconActiveName: 'team-blue',
        iconDisableName: 'team-lighten',
        path: '',
        activePaths: [],
        disable: true,
        isTest: false,
      },
    ],
  },
  {
    title: 'nav.inbox',
    iconName: 'inbox',
    iconActiveName: 'inbox-blue',
    iconDisableName: 'inbox-lighten',
    path: '',
    activePaths: [],
    disable: true,
    isTest: false,
  },
  {
    title: 'nav.settings',
    iconName: 'settings',
    iconActiveName: 'settings-blue',
    iconDisableName: 'settings-lighten',
    path: '',
    activePaths: [],
    disable: true,
    isTest: false,
  },
];

export default clientItems;
