var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "checkbox-english-wraper py-4 px-6" },
    [
      _c("v-switch", {
        staticClass: "custom-checkbox-english mt-0 pt-0",
        attrs: { color: "primary" },
        on: { change: _vm.handleChange },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c(
                  "span",
                  { staticClass: "text-body-1 text--text text--base" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "explore.more-filters.modal.filter.filter-label-lang"
                        )
                      )
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }