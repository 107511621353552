<template>
  <Requests v-if="isClient && !isBoth" />
  <RequestsGestor v-else-if="isGestor && !isBoth" />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from '@/plugins/vuex';
import Requests from '@/sections/requests/RequestsWrapper.vue';
import RequestsGestor from '@/sections/requests-gestor/RequestsGestorWrapper.vue';

const store = useStore();
const accountRole = computed(() => (store.getters.account ? store.getters.account.authorities : []));

const isClient = computed(() => accountRole.value.includes('ROLE_CLIENT'));
const isGestor = computed(() => accountRole.value.includes('ROLE_GESTOR'));
const isBoth = computed(() => isClient.value && isGestor.value);
</script>
