import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { computed, defineComponent, onMounted, ref } from 'vue';

export enum StatusFilter {
  All = 'all',
  Saved = 'saved',
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export default defineComponent({
  props: {
    listTeamRequest: {
      type: Array,
      default: () => [],
    },
    getStatusSelectedAndFilter: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const statusSelected = ref('');
    const btnSelected = ref('all');
    const statusCount = computed(() => {
      return {
        saved: props.listTeamRequest.filter((teamRequest: any) => teamRequest.status === RequestStatus.SAVED).length,
        pending: props.listTeamRequest.filter(
          (teamRequest: any) => teamRequest.status === RequestStatus.PENDING || teamRequest.status === RequestStatus.VALIDATED
        ).length,
        approved: props.listTeamRequest.filter((teamRequest: any) => teamRequest.status === RequestStatus.ON_GOING).length,
        rejected: props.listTeamRequest.filter((teamRequest: any) => teamRequest.status === RequestStatus.CANCELLED_BY_MANAGER).length,
      };
    });

    const getStatusButton = (statusCount: Record<string, number>) => {
      const filteredStatus = Object.entries(statusCount)
        .filter(([key, value]) => (value as number) >= 0)
        .map(([key, value]) => ({ name: key, count: value }));

      return filteredStatus;
    };

    const getActive = name => {
      btnSelected.value = name;
      props.getStatusSelectedAndFilter(name);
    };

    return {
      statusSelected,
      getStatusButton,
      statusCount,
      btnSelected,
      getActive,
    };
  },
});
