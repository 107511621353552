export enum Authority {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
  CLIENT = 'ROLE_CLIENT',
  RRHH = 'ROLE_RRHH',
  STEMDOER = 'ROLE_STEMDOER',
  GESTOR = 'ROLE_GESTOR',
}

export function getAuthorityEnum(val: string) {
  const instanceKey = Object.keys(Authority).find(key => Authority[key as keyof typeof Authority] === val);
  return Authority[instanceKey as keyof typeof Authority];
}
